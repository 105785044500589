/*.check-out-page-main{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 30px 80px 40px 80px;
    max-width: 1500px;
}

.check-out-page-pane{
    flex-grow: 1;
}

.check-out-page-cart-menu{
    background-color: #ce3c25;
    color: #fff;
    font-size: 16px;
    text-align: center;
    width: 250px;
    height: 300px;
    margin-left: 30px;
}

@media only screen and (max-width: 950px /*SMALL_MEDIA_SIZE) {
  .check-out-page-main{
    display: flex;
    flex-direction: column;

    margin: 20px 20px;
  }

}
*/