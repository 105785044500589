.main-product-details-main{
  margin: 0px 40px 20px 40px;
}

.main-product-details-main>h3{
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 3px;
}

.main-product-details-main>p{
  font-size: 14.5px;
}