.price-information-notice-container {
  background-color: #fff;
  text-align: left;
  border: 1px solid #b7b7b7;
  border-radius: 2px;

  width: 244px;
  padding: 5px;
  z-index: 10;
}

.price-information-notice-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  height: auto;
}

.price-information-notice-content > p {
  font-size: 11px;
  font-weight: 200;
  margin: 0px;
}
