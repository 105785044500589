/* Related to the header */
.top-level-error-boundary-logo-image {
  width: 100%;
  height: auto;
}

.top-level-error-boundary-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  color: #eb5b1e;
  letter-spacing: 1px;
  font-family: Roboto;
}

.top-level-error-boundary-logo-container {
  position: relative;
  height: 120px; /* This container must have a set height and width for proper alignment*/
  width: 500px;
}

.top-level-error-boundary-logo-animate {
  animation: TopLevelErrrorBoundaryMoveUpDownLogo 2s ease-in-out infinite;
  position: absolute;
  left: 0;
  bottom: 0px;
}

@media only screen and (max-width: 500px /*EXTRA_SMALL_MEDIA_SIZE*/) {
  .top-level-error-boundary-logo-container {
    height: 120px;
    width: 300px;
  }
}

@keyframes TopLevelErrrorBoundaryMoveUpDownLogo {
  0% {
    bottom: 0;
    transform: rotate(0deg);
  }

  36% {
    bottom: 0px;
    transform: rotate(0deg);
  }

  50% {
    bottom: 10px;
    transform: rotate(0deg);
  }

  64% {
    bottom: 0px;
    transform: rotate(0deg);
  }

  100% {
    bottom: 0;
    transform: rotate(0deg);
  }
}

/* Related to the loader */
.top-level-error-boundary-loader {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 200;

  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 60px 0px 60px 0px;
}

.top-level-error-boundary-sub-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-level-error-boundary-loader-text {
  color: #eb5b1e;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 10px;
  text-align: center;
  max-width: 900px;
}

.top-level-error-boundary-loader-container {
  position: relative;
  height: 80px; /* This container must have a set height and width for proper alignment*/
  width: 105px;
}

.top-level-error-boundary-loader-image {
  width: auto;
  height: 100%;
}

.top-level-error-boundary-loader-animate {
  animation: TopLevelErrrorBoundaryMoveUpDown 2s ease-in-out infinite;
  position: absolute;
  left: 0;
  bottom: 0px;
}

@keyframes TopLevelErrrorBoundaryMoveUpDown {
  0% {
    bottom: 0;
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(2deg);
  }

  50% {
    bottom: 70px;
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-2deg);
  }

  100% {
    bottom: 0;
    transform: rotate(0deg);
  }
}
