.checkbox-div {
  font-size: 14px;
  padding-bottom: 5px;
}

.checkbox-div > label {
  cursor: pointer;
}

.checkbox-div > label > input {
  margin-bottom: -3px;
}
.checkbox-div input[type='checkbox'] {
  opacity: 0;
  position: absolute;
  width: 12px;
  height: 12px;
}

.checkbox-div input[type='checkbox'] + span:before {
  content: '\0a0';
  display: inline-block;
  margin-right: 5px;
  width: 15px;
  height: 15px;
  visibility: visible;
  border: 1px solid #b1b1b1;
  border-radius: 2px;
  line-height: 13px;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
}

.checkbox-div input[type='checkbox']:checked + span:before {
  background-color: #2eb411;
  content: '\2713';
  color: #fff;
  font-size: 12px;
}
