.login-box-main {
  background-color: #ffffff;
  text-align: center;

  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
}

.login-box-main > h3 {
  color: #292b2c;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;

  margin: 10px 0px;
}

.login-box-forgot-password {
  margin-top: 5px;
}

.login-box-forgot-password > a {
  color: #626166;
  font-size: 14px;
  font-weight: 200px;
  line-height: 17px;
}

.login-box-forgot-password > a {
  color: #0275d8;
}

.login-box-forgot-password > a:hover {
  color: #0275d8;
}

.login-box-input {
  background-color: #f6f6f6;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-weight: 200;
  text-indent: 15px;
  letter-spacing: 1px;

  height: 55px;
  width: 100%;
  margin-bottom: 10px;
}

.login-box-input-error {
  border: 1px solid #ce3c25 !important;
}

@media only screen and (max-width: 750px /*SMALL_MEDIA_SIZE*/) {
  .login-box-main {
    margin: 0px 5px;
  }

  .login-box-input {
    font-size: 16px;
  }
}
