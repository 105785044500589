.barbecue-line-item-container {
  padding: 15px 15px 0 15px;

  background-color: white;
  border-radius: 0 0 2px 2px;
}
.barbecue-line-item-content {
  display: grid;
  grid-template-columns: 4fr 7fr 5fr 3fr;
  margin: auto;
  text-align: center;
}

.barbecue-line-item-content .text {
  display: flex;

  font-size: 14px;

  align-items: center;
}

.barbecue-line-item-content .product-image {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2vw;
}
.product-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
}
.barbecue-line-item-content .product-name {
  text-transform: capitalize;
  text-align: center;
}

.barbecue-line-item-content .product-manufacturer {
  font-size: 12px;
}
.barbecue-line-item-content .product-price {
  color: green;
  justify-content: center;
}
.barbecue-line-item-content .product-quantity-container {
  display: flex;
  align-items: center;
  text-align: center;
}
.barbecue-line-item-content .product-quantity {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}
.barbecue-line-item-end-line {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  height: 0;
  margin-top: 15px;
  border: 1px solid #b7b7b7;
}

.barbecue-line-item-content .center {
  justify-content: center;
}
@media screen and (max-width: 750px) {
  .barbecue-line-item-content .text {
    font-size: 0.6rem;
  }

  .barbecue-line-item-content .product-manufacturer {
    font-size: 0.5rem;
  }
  .barbecue-line-item-content .product-quantity {
    font-size: 0.5rem;
  }
}
