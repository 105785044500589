.order-total-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
}
.order-total-main-container {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  color: #212529;
  margin: 0px 75px 0px 0px;
}

.order-total-line {
  width: 100%;
  border-width: 2px;
}

.order-total-main-container > div {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.order-total-main-container > div > p {
  height: 35px;
  margin: 0px;
}

.order-total-main-container > div > h3 {
  font-size: 18px;
  height: 35px;
  margin: 2px 0px 0px 0px;
  font-weight: 100;
}

.order-total-final-price {
  font-weight: 800 !important;
}

.order-total-alert-weigh {
  text-align: right;
  font-size: 18px;
  margin: 15px 0px;
}

.order-total-green-text {
  color: #2eb411;
}

@media only screen and (max-width: 500px) {
  .order-total-not-weighed {
    text-align: right;
    font-size: 14px;
    margin: 15px 5px;
  }

  .order-total-main-container > div > p {
    font-size: 14px;
  }

  .order-total-main-container > div > h3 {
    font-size: 14px;
  }

  .order-total-main-container {
    margin: 0px 25px 20px 0px;
  }
}
