.option-main{
  margin-top: 15px;
  text-decoration: none;

}

.option-name{
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;

}

.option-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.option-container>a:focus {
  text-decoration: none;
}

.option-container>a:hover {
  text-decoration: none;
}

@media only screen and (max-width: 420px /*SMALL_MEDIA_SIZE*/) {
  .option-main>div{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .option-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}