.faq-page-hits {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 30px;
}

@media only screen and (max-width: 350px) {
  .faq-page-hits {
    margin: 10px 15px;
  }
}
