.no-orders-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #ce3c25;
  font-size: 20px;
  text-align: center;
}

.no-orders-stamp {
  width: 170px;
  margin: 30px 0px;
}
