.register-box-main {
  background-color: #ffffff;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
  width: 100%;
}

.register-box-main > h3 {
  color: #ce3c25;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;

  margin: 10px 0px;
}

.register-box-input {
  background-color: #f6f6f6;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-weight: 200;
  text-indent: 15px;
  letter-spacing: 1px;

  height: 55px;
  width: 100%;
  margin-bottom: 10px;
}

.register-box-input-error {
  border: 1px solid #ce3c25 !important;
}

.register-box-terms-of-use > span {
  font-size: 14px;
  margin-left: -8px;
}

.password-strength-text {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 200;
  float: right;
  line-height: 15px;
  color: #4a4a4a;
}

.password-strength-strong {
  color: #59b259;
  font-weight: bold;
}

.password-strength-medium {
  color: #e77600;
  font-weight: bold;
}

.password-strength-poor {
  color: #d0021b;
  font-weight: bold;
}

@media only screen and (max-width: 750px /*SMALL_MEDIA_SIZE*/) {
  .register-box-main {
    margin: 0px 5px;
  }

  .register-box-input {
    font-size: 16px;
  }
}
