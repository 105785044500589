.delivery-summary-main>p{
  font-size: 14px;
  letter-spacing: 1px;
  margin: 0px !important;
}

.delivery-summary-details{
  display: flex;
}

@media only screen and (max-width: 500px /*SMALL_MEDIA_SIZE*/) {
  .delivery-summary-main>p{
    font-size: 12px;
  }
}