.my-account-address-main{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-account-address-main>h3 {
  font-size: 20px;
  font-weight: 100;
  width: 100%;
  margin-bottom: 15px;
}

.my-account-address-content {
  max-width: 600px;
  width: 100%;
}

.my-account-address-edit-item {
  max-width: 600px;
}
.my-account-address-item-main {
  width: 100%;
}        

.my-account-address-add-new-button{
  width: 100%;
  max-width: 600px;
}