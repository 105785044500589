.my-account-address-add-new-button-main {
  background-color: #ce3c25;
  color: #fff;

  font-weight: 200;

  display: flex;
  flex-direction: row-reverse;

  width: 100%;
  height: 40px;

  margin-bottom: 20px;
}

.my-account-address-add-new-button-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  margin: 0px 20px 0px 30px;
}

.my-account-address-add-new-text {
  width: 100%;
  text-align: center;
}

.my-account-address-add-new-button-main:hover {
  cursor: pointer;
}

.my-account-address-add-new-arrow-up {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;

  border-bottom: 7px solid #fff;
}

.my-account-address-add-new-arrow-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;

  border-top: 7px solid #fff;
}

@media only screen and (max-width: 500px /*SMALLER_MEDIA_SIZE*/) {
  .my-account-address-add-new-button-content {
    font-size: 14px;
  }
}

@media only screen and (max-width: 370px /*SMALLER_MEDIA_SIZE*/) {
  .my-account-address-add-new-button-content {
    font-size: 12px;
    margin: 0px 10px 0px 20px;
  }
}
