.recipe-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.recipe-header {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.recipe-header-title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-left: 40px;
  margin-top: 10px;
  text-align: center;
}

.recipe-body-main-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 3px;
}

.recipe-body-main-content-player {
  width: 100%;
}

.recipe-body-main-content-recipe-steps {
  margin: 0px 40px;
  color: #4a4a4a;
}

@media only screen and (max-width: 750px) {
  .recipe-body-main-content {
    flex-direction: column;
  }
}
