@font-face {
  font-family: QUARTZO-Light;
  src: url('../fonts/QUARTZO-light.ttf');
}

.color-row-main {
  margin-bottom: 0 !important;
}
.blend-lading-page-main {
  font-family: Open Sans, sans-serif;
}
.blend-lading-full-image {
  width: 100%;
  height: auto;
}
.blend-lading-rectangle-1 {
  position: relative;
  width: 100%;
  height: 482px;

  background: #000000;
  color: #ededed;
}
.food-image {
  position: absolute;
  top: 23.7%;
  left: 46.8%;
}
.rectangle-text {
  position: absolute;
  width: 30%;
  height: 130px;
  top: 42.3%;
  left: 35%;

  line-height: normal;
  text-align: center;
}

.blend-lading-rectangle-2 {
  position: relative;
  width: 100%;
  height: 553px;
  background: #ec5a1d;
}

.meat-image {
  position: absolute;
  width: 47.6%;
  /*height: 115.2%;*/
  top: 15%;
  left: 5.2%;
}

.rectangle-text-2 {
  position: absolute;
  width: 35%;
  height: 250px;
  top: 42.3%;
  left: 59%;

  line-height: normal;
  text-align: center;
  color: #ffffff;
}

.text-size-large {
  font-family: QUARTZO-Light;

  font-size: 24px;
}

.text-size-small {
  font-family: 'Open Sans', sans-serif;

  font-size: 21px;
}

.divisory-image-top {
  width: 100%;
  margin-top: -8px;
}
.divisory-image-bottom {
  width: 100%;
  margin-top: -2px;
}

.blend-lading-rectangle-3 {
  position: relative;
  width: 100%;
  height: 743px;
}

.number-image-1 {
  font-family: QUARTZO-Light;
  position: relative;
  top: 32.5%;
  left: 9%;
  color: #ececec;
  font-size: 430px;
}

.rectangle-text-3 {
  position: absolute;
  width: 40%;
  height: 76px;
  top: 36.3%;
  left: 33%;

  line-height: normal;
  text-align: center;
  color: #ec5a1d;
}

.combo-image-1 {
  position: absolute;
  width: 55%;
  top: 53%;
  left: 28%;
}

.cupim-image-1 {
  width: 196px;
  height: 238px;
}
.file-image-1 {
  width: 232px;
  height: 302px;
}
.fraldinha-image-1 {
  width: 233px;
  height: 235px;
}

.plus-orange {
  color: #ec5a1d;
  line-height: normal;
  font-size: 24px;
  text-align: center;
  margin: 0 10px;
}

/* RECTANGLE 4 */
.blend-lading-rectangle-4 {
  position: relative;
  width: 100%;
  height: 583px;
  background: #ec5a1d;
  color: #ffffff;
}

.number-image-2 {
  font-family: QUARTZO-Light;
  position: relative;
  top: -4.5%;
  left: 4%;
  color: #dc5831;
  font-size: 470px;
}

.rectangle-text-4 {
  position: absolute;
  width: 40%;
  height: 124px;
  top: 15%;
  left: 38%;

  line-height: normal;
  text-align: center;
}
.combo-image-2 {
  position: absolute;
  width: 55%;
  top: 41%;
  left: 31%;
}

.plus-white {
  color: #ffffff;
  line-height: normal;
  font-size: 24px;
  text-align: center;
  margin: 0 10px;
}

/* RECTANGLE 5 */
.blend-lading-rectangle-5 {
  position: relative;
  width: 100%;
  height: 743px;
}
.number-image-3 {
  font-family: QUARTZO-Light;
  position: absolute;
  top: 7.5%;
  left: 7%;
  color: #ececec;
  font-size: 550px;
}

.burger-image {
  position: absolute;
  top: 29%;
  left: 69%;
}

.blends {
  position: relative;
  top: 36%;
  left: 17%;

  width: 70%;
  height: 310px;
}

.rectangle-text-5 {
  position: absolute;
  width: 32%;
  height: 146px;
  top: 13%;
  left: 35%;

  line-height: normal;
  text-align: center;
  color: #ec5a1d;
}

/* RECTANGLE 6 */
.blend-lading-rectangle-6 {
  position: relative;
  width: 100%;
  height: 525px;

  background: #ec5a1d;
}

.rectangle-text-6 {
  position: absolute;
  width: 40%;
  height: 124px;
  top: 36%;
  left: 32.5%;

  line-height: normal;
  text-align: center;
  color: #ffffff;
}

.blend-button {
  position: absolute;
  width: 25%;
  height: 51px;
  top: 64%;
  left: 39.5%;

  border: none;
  background: #ffffff;
  color: #ec5a1d;
}

.blend-button:hover {
  cursor: pointer;
}
@media only screen and (max-width: 1125px) {
  /* RECTANGLE 1 */
  .blend-lading-rectangle-1 {
    height: 360px;
  }
  .food-image {
    top: 18.7%;
    left: 45%;
  }
  /* RECTANGLE 2 */
  .blend-lading-rectangle-2 {
    height: 480px;
  }
  .rectangle-text-2 {
    top: 33.3%;
  }
  /* RECTANGLE 3 */
  .blend-lading-rectangle-3 {
    height: 500px;
  }
  .number-image-1 {
    top: 5.5%;
    left: 9%;
  }
  .rectangle-text-3 {
    height: auto;
    top: 16.3%;
    left: 38%;
  }
  .combo-image-1 {
    width: 71%;
    top: 40%;
    left: 28.5%;
  }
  .cupim-image-1 {
    width: 167px;
    height: 212px;
  }
  .file-image-1 {
    width: 214px;
    height: 278px;
  }
  .fraldinha-image-1 {
    width: 214px;
    height: 219px;
  }
  /* RECTANGLE 4 */
  .blend-lading-rectangle-4 {
    height: 483px;
  }
  .combo-image-2 {
    width: 68%;
    top: 47%;
    left: 29.5%;
  }
  .cupim-image-2 {
    width: 200px;
  }

  .file-image-2 {
    width: 189px;
  }

  .fraldinha-image-2 {
    width: 175px;
  }
  /* RECTANGLE 5 */
  .blend-lading-rectangle-5 {
    height: 630px;
  }
  .rectangle-text-5 {
    width: 34%;
  }
  .burger-image {
    width: 40%;
    left: 60%;
  }
  /* RECTANGLE 6 */
  .rectangle-text-6 {
    width: 52%;
    top: 32%;
    left: 40.5%;
  }
  .blend-button {
    top: 56%;
    left: 53.5%;
  }
}
@media only screen and (max-width: 960px) {
  /*RECTANGLE 1*/
  .blend-lading-rectangle-1 {
    height: 250px;
  }
  .food-image {
    width: 12%;
    top: 9.7%;
    left: 43.8%;
  }
  .rectangle-text {
    width: 40%;
    top: 47.3%;
    left: 29.5%;
    height: auto;
  }
  .text-size-large {
    font-size: 21px;
    margin-bottom: 5px;
  }
  .text-size-small {
    font-size: 17px;
  }

  /*RECTANGLE 2*/
  .blend-lading-rectangle-2 {
    height: 350px;
  }
  .meat-image {
    width: 42.6%;
    top: 22%;
    left: 1.2%;
  }
  .rectangle-text-2 {
    width: 44%;
    height: auto;
    top: 33.3%;
    left: 48.5%;
  }
  .divisory-image-top {
    margin-top: -16px;
  }
  .divisory-image-bottom {
    margin-top: 14px;
  }

  /*RECTANGLE 3*/
  .blend-lading-rectangle-3 {
    height: 330px;
  }

  .number-image-1 {
    top: 5%;
    left: 6%;
    font-size: 300px;
  }
  .rectangle-text-3 {
    width: 60%;
    height: auto;
    top: 14.3%;
    left: 22.5%;
  }
  .combo-image-1 {
    width: 70%;
    top: 47%;
    left: 27.5%;
  }
  .cupim-image-1 {
    width: 122px;
    height: 137px;
  }
  .file-image-1 {
    width: 122px;
    height: 137px;
  }
  .fraldinha-image-1 {
    width: 122px;
    height: 137px;
  }
  .plus-orange {
    font-size: 20px;
  }

  /*RECTANGLE 4*/
  .blend-lading-rectangle-4 {
    height: 375px;
  }
  .number-image-2 {
    font-size: 300px;
    top: 9.5%;
    left: 2%;
  }
  .rectangle-text-4 {
    width: 55%;
    height: auto;
    top: 21%;
    left: 24%;
  }
  .combo-image-2 {
    width: 74%;
    top: 48%;
    left: 24.5%;
  }
  .cupim-image-2 {
    width: 150px;
  }

  .file-image-2 {
    width: 140px;
  }

  .fraldinha-image-2 {
    width: 132px;
  }

  .plus-white {
    font-size: 20px;
  }
  /*RECTANGLE 5*/
  .blend-lading-rectangle-5 {
    height: 400px;
  }
  .number-image-3 {
    font-size: 300px;
    top: 14.5%;
    left: 5%;
  }
  .rectangle-text-5 {
    width: 54%;
    height: auto;
    top: 9%;
    left: 21.5%;
  }
  .blends {
    top: 44%;
    height: auto;
  }
  .blend-2 {
    display: none;
  }
  .burger-image {
    width: 40%;
    left: 60%;
    top: 19%;
  }
  /*RECTANGLE 6*/
  .blend-lading-rectangle-6 {
    height: 400px;
  }
  .cart-image {
    width: 28%;
    position: absolute;
    top: 16%;
  }
  .rectangle-text-6 {
    width: 70%;
    height: auto;
    top: 39%;
    left: 26.5%;
  }

  .blend-button {
    width: 29%;
    height: 46px;
    top: 61%;
    left: 46%;
    font-size: 15px;
  }
}

@media only screen and (max-width: 500px) {
  /*RECTANGLE 1*/
  .blend-lading-rectangle-1 {
    height: 135px;
  }
  .food-image {
    width: 13%;
    top: 6.7%;
  }
  .rectangle-text {
    top: 43.3%;
    left: 30.5%;
  }
  .text-size-large {
    font-size: 12px;
  }
  .text-size-small {
    font-size: 10px;
  }

  /*RECTANGLE 2*/
  .blend-lading-rectangle-2 {
    height: 180px;
  }
  .meat-image {
    width: 42.6%;
    top: 22%;
    left: 1.2%;
  }
  .rectangle-text-2 {
    top: 26.3%;
  }
  .divisory-image-top {
    margin-top: -21px;
  }
  .divisory-image-bottom {
    margin-top: 14px;
  }

  /*RECTANGLE 3*/
  .blend-lading-rectangle-3 {
    height: 140px;
  }

  .number-image-1 {
    top: 2%;
    left: 7%;
    font-size: 150px;
  }
  .rectangle-text-3 {
    top: 5.3%;
    left: 29.5%;
  }
  .combo-image-1 {
    left: 26.5%;
  }
  .cupim-image-1 {
    width: 65px;
    height: 77px;
  }
  .file-image-1 {
    width: 65px;
    height: 77px;
  }
  .fraldinha-image-1 {
    width: 65px;
    height: 77px;
  }
  .plus-orange {
    font-size: 13px;
  }

  /*RECTANGLE 4*/
  .blend-lading-rectangle-4 {
    height: 190px;
  }
  .number-image-2 {
    font-size: 150px;
    top: 13.5%;
  }
  .rectangle-text-4 {
    top: 12%;
    left: 32%;
  }
  .cupim-image-2 {
    width: 73px;
  }

  .file-image-2 {
    width: 69px;
  }

  .fraldinha-image-2 {
    width: 65px;
  }

  .plus-white {
    font-size: 11px;
  }
  /*RECTANGLE 5*/
  .blend-lading-rectangle-5 {
    height: 200px;
  }
  .number-image-3 {
    font-size: 150px;
    top: 22.5%;
    left: 4%;
  }
  .rectangle-text-5 {
    top: 1%;
  }
  .blends {
    top: 45%;
    left: 17%;
  }
  .blends > .blend-1 > div > .blend-hit {
    height: 100px;
  }
  .blends > .blend-1 > div > .blend-hit > .blend-actions {
    width: 42%;
  }
  .blends
    > .blend-1
    > div
    > .blend-hit
    > .blend-actions
    > .blend-hit-quantity
    > .add-to-cart-before-button {
    width: 101%;
    margin: 5px auto 0 auto;
  }
  .blends > .blend-1 > div > .blend-hit > .blend-hit-info {
    width: 51%;
    min-width: auto;
  }

  .burger-image {
    top: 29%;
  }
  /*RECTANGLE 6*/
  .blend-lading-rectangle-6 {
    height: 200px;
  }
  .cart-image {
    width: 28%;
    position: absolute;
    top: 16%;
  }
  .rectangle-text-6 {
    width: 70%;
    height: auto;
    top: 39%;
    left: 26.5%;
  }

  .blend-button {
    width: 33%;
    height: 20px;
    top: 67%;
    left: 46%;
    font-size: 8px;
  }
}

@media only screen and (max-width: 360px) {
  .cupim-image-1 {
    width: 60px;
    height: 71px;
  }
  .cupim-image-2 {
    width: 68px;
  }

  .file-image-2 {
    width: 64px;
  }

  .fraldinha-image-2 {
    width: 60px;
  }
}
