.add-to-cart-before-button {
  background-color: #eb5b1e !important;
  color: #fff !important;
  font-size: 20px;
  font-weight: 700;

  border: 0px solid #fff;
  border-radius: 3px !important;
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 1);

  display: flex;
  justify-content: space-around;
  align-items: center;

  padding: 3px 15px;

  width: 120px;
  height: 40px;
}

.add-to-cart-test {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.add-to-cart-before-button > i {
  height: 22px;
}

.add-to-cart-icon {
  width: 35px;
  height: 35px;
}

.add-to-cart-before-button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 450px) {
  .add-to-cart-before-button {
    margin: 5px auto 0 auto;
  }
}
