@font-face {
  font-family: QUARTZO-Light;
  src: url('../fonts/QUARTZO-light.ttf');
}
.barbecue-calculator-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.barbecue-calculator-header {
  background-color: #000;
  color: #fff;
  font-family: QUARTZO-Light;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2vw 0;
  text-align: center;
}
.barbecue-calculator-header .header-title {
  font-size: 40px;
  margin: 0;
  font-family: QUARTZO-Light;
}
.barbecue-calculator-header .header-subtitle {
  font-size: 18px;
  margin: 0;
}
.barbecue-calculator-content {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 750px) {
  .barbecue-calculator-content {
    display: flex;
    flex-direction: column;
  }
  .barbecue-calculator-header .header-title {
    font-size: 2rem;
  }
  .barbecue-calculator-header .header-subtitle {
    font-size: 0.8rem;
  }
}
