.line-item-list-main {
  width: 100%;
  max-width: 1500px;
}

.line-item-list-container {
  display: flex;
  flex-direction: column;
}

.line-item-list-line {
  width: 100%;
  border-width: 2px;
  margin: 5px 0px;
}

.line-item-list-subcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 25px;
  height: 140px;
}

.line-item-list-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*min-height:75px;*/
  /* max-width: 450px; */
  width: 100%;
  margin-right: 5px;
  height: auto;
}

.line-item-list-content > h3 {
  font-size: 20px;
}

.line-item-list-content > a > h3 {
  color: #212121 !important;
  font-size: 22px;
  font-weight: 700;
  margin: 0px;
}

.line-item-list-content > p {
  font-size: 16px;
  margin-bottom: 5px;
}

.line-item-list-content > a:hover {
  cursor: pointer;
  text-decoration: none;
}

.line-item-list-price-content {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  height: 140px;
  width: 100%;
  max-width: 130px;
  margin: 0px 0px 0px 15px;
}

.line-item-list-price-content-estimated {
  color: #b0b0b2;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  height: 140px;
  width: 100%;
  max-width: 130px;
  /* margin: 0px 0px 0px 15px; */
}

.line-item-list-price-content-estimated > p {
  font-size: 14px;
  margin: 5px 0px 0px 0px;
}

.line-item-list-price-content-estimated > h3 {
  font-size: 20px;
  font-weight: 700;
}

.line-item-list-price-content > p {
  font-size: 14px;
  margin: 5px 0px 0px 0px;
}

.line-item-list-price-content > h3 {
  font-size: 20px;
  font-weight: 700;
}

.line-item-list-price-content-final-price {
  color: #2eb411;
  font-size: 20px;
  font-weight: 700;
}

.line-item-list-mix-name {
  color: #640064;
}

@media only screen and (max-width: 850px) {
  .line-item-list-subcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*flex-wrap: wrap;*/
    align-items: center;
    margin: 0px 5px;
    height: auto;
  }

  .line-item-list-price-content-estimated {
    width: 40%;
    margin-left: 20px;
  }

  .line-item-list-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .line-item-list-content > h3 {
    font-size: 18px;
    margin: 0px;
  }

  .line-item-list-price-content {
    margin-right: 30px;
    margin-left: 20px;
  }

  .line-item-list-line {
    margin: 5px 0px;
  }

  .line-item-list-content > a > h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 500px) {
  .line-item-list-subcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0px 5px;
  }

  .line-item-list-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;

    margin-bottom: 10px;
  }

  .line-item-list-subcontent {
    color: #626166;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .line-item-list-content > a > h3 {
    font-size: 16px !important;
    margin-top: 5px;
  }

  .line-item-list-subcontent > h3 {
    font-size: 14px !important;
  }

  .line-item-list-price-content > p {
    margin: 0px;
  }

  .line-item-list-price-content > h3 {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 350px) {
  .line-item-list-price-content {
    width: 100px;
    margin: 0px;
  }

  .line-item-list-price-content-estimated {
    width: 100px;
    margin: 0px;
  }

  .line-item-list-price-content-estimated > p {
    font-size: 12px;
  }

  .line-item-list-price-content-estimated > h3 {
    font-size: 18px;
    font-weight: 700;
  }

  .line-item-list-price-content > p {
    font-size: 12px;
  }

  .line-item-list-price-content > h3 {
    font-size: 18px;
    font-weight: 700;
  }
}
