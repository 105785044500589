.image-list-control {
  width: 200px;
  display: flex;
  justify-content: center;
}

.image-list-main {
  min-width: 100px;
  max-width: 160px;
  margin: 0px 15px 0px 0px;
}

@media only screen and (max-width: 500px /*EXTRA_SMALL_MEDIA_SIZE*/) {
  .image-list-control {
    width: 150px;
  }

  .image-list-main {
    max-width: 120px;
  }
}
