/* Todo: Remove importants when we can remove the old button without everything breaking....*/

.form-button-main {
    color: #ffffff;
    font-weight: 900;
    font-size: 15px;
    text-align: center;

    border-radius: 0px !important;

    border: 0px solid #fff !important;
    box-shadow: #fff !important;

    cursor: pointer;

    height: 40px !important;
    width: 100% !important;
    padding: 3px 15px;
}

.form-button-tertiary {
    background-color: #a5a5a5;
    color: #2f2f2f;
}

.form-button-icon {
    height: 20px;
    width: 19px;
    margin: 0px 10px 0px 0px;
}

.form-button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
}

.form-button-just-text{
    padding: 0px 25px;
}