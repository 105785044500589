.barbecue-option-value-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
}
.barbecue-option-value-content {
  font-weight: 300;
  letter-spacing: 1px;

  border: 1px solid #b7b7b7;
  border-radius: 2px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  width: 160px;
  height: 55px;
  padding: 3px;
  margin-right: 10px;
  margin-bottom: 10px;

  transition: 0.05s;
  cursor: pointer;
}
.barbecue-option-value-content.selected {
  border-color: #000;
  border-width: 2px;
}
.barbecue-option-value-content .text {
  display: flex;
  align-items: center;
  font-family: QUARTZO-Light;
  font-size: 15px;
  color: #4a4a4a;
}
.barbecue-option-value-content.selected .text {
  color: #000;
}
.barbecue-option-value-image {
  width: 40px;
  height: auto;
}
@media only screen and (max-width: 750px) {
  .barbecue-option-value-content .text {
    font-size: 15px;
  }
  .barbecue-option-value-image {
    width: 25px;
    height: auto;
  }
  .barbecue-option-value-content {
    padding: 1rem 1rem;
  }
}
