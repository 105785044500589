.delivery-picker-option-main {
  color: #212121;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: 'Open Sans', sans-serif;

  border: 1px solid #fff;

  display: flex;
  align-items: center;

  width: 100%;
  height: 50px;

  margin: 1px;
  padding: 0px;
}

.delivery-picker-option-main:hover {
  border: none;
}

.delivery-picker-option-main:focus {
  outline: 0 none;
}

.delivery-picker-option-main > label:hover {
  cursor: pointer;
}

.delivery-picker-option-selected {
  background-color: #c0e8b7 !important;
  border: 1px solid #2eb411;
}

.delivery-picker-option-selected > label > .delivery-picker-date-container {
  background-color: #28a20f !important;
}

.delivery-picker-light {
  background-color: #f6f6f6;
}

.delivery-picker-dark {
  background-color: #ededed;
}

.delivery-picker-option-main > label {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0px;
  justify-content: space-between;
}

.delivery-picker-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 100%;
}

.delivery-picker-speed-container {
  display: flex;
  flex-direction: row-reverse;
  /*min-width: 150px;
  max-width: 250px;*/
  margin: 0px 10px;
}

.delivery-picker-speed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delivery-picker-deliveries-container {
  width: 40%;
  text-align: center;
}

.delivery-picker-deliveries-full {
  color: #ce3c25;
}

.delivery-picker-deliveries-avaible {
  color: #eb5b1e;
}

.delivery-picker-deliveries-popular {
  color: #28a20f;
}

.delivery-picker-time-container {
  width: 40%;
  margin-left: 20px;
  text-align: center;
}

.express {
  width: 43%;
}

.delivery-picker-date-container {
  background-color: #ce3c25;
  color: #fff;
  width: 50px !important;
  height: 50px;
}

.delivery-picker-free {
  color: #2eb411;
  font-weight: 700;
}

.disable {
  pointer-events: none;
  opacity: 0.4;
}
@media only screen and (max-width: 450px) {
  .delivery-picker-deliveries-container {
    width: 62%;
  }
  .delivery-picker-time-container {
    margin-left: 10px;
    width: 85%;
  }

  .express {
    width: inherit;
  }
}
@media only screen and (max-width: 370px) {
  .delivery-picker-option-main {
    font-size: 12px;
  }

  .delivery-picker-date-container {
    width: 40px !important;
  }

  .delivery-picker-date {
    width: 40px;
  }

  .delivery-picker-speed-container {
    width: auto;
  }

  /*.delivery-picker-speed-name{
    display: none;
  }*/
}

@media only screen and (max-width: 270px) {
  .delivery-picker-option-main {
    font-size: 10px;
  }

  .delivery-picker-date-container {
    width: 30px !important;
    margin-right: 10px;
  }

  .delivery-picker-date {
    width: 30px;
  }

  .delivery-picker-time-container {
    width: 50%;
  }

  .delivery-picker-speed-container {
    min-width: 50px;
  }
}
