.barbecue-calculator-form-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#barbecue-calculator-form-content {
  width: 80%;
  padding: 0 0 5vw 0;
  height: 100%;
}
.barbecue-calculator-people-quantity-selector {
  display: flex;
  flex-direction: column;
}
.barbecue-calculator-people-quantity-selector .quantity-selector-title,
.barbecue-calculator-fat-selector .fat-selector-title,
.barbecue-calculator-cut-type .cut-type-title,
.barbecue-calculator-accompaniments .accompaniments-title {
  font-family: QUARTZO-Light;
  text-align: center;
  font-size: 30px;
  padding: 2vw 0 1vw 0;
  color: #eb5b1c;
}
.barbecue-calculator-people-quantity-selector .quantity-selector-title,
.barbecue-calculator-fat-selector .fat-selector-title,
.barbecue-calculator-cut-type .cut-type-title,
.barbecue-calculator-accompaniments .accompaniments-title:not(:first-child) {
  margin-top: 2vw;
}
.barbecue-calculator-people-quantity-selector .quantity-selector-subtitle,
.barbecue-calculator-fat-selector .fat-selector-subtitle,
.barbecue-calculator-cut-type .cut-type-subtitle,
.barbecue-calculator-accompaniments .accompaniments-subtitle {
  font-size: 14px;
  text-align: center;
}
.barbecue-calculator-people-quantity-selector .quantity-selector-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1vw;
}
.barbecue-calculator-fat-selector .fat-selector-slider-container {
  width: 100%;
}
.barbecue-calculator-fat-selector
  .fat-selector-slider-container
  .fat-selector-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 1vw;
  border-radius: 2px;
  background: #f6f6f6;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.barbecue-calculator-fat-selector
  .fat-selector-slider-container
  .fat-selector-slider:hover {
  opacity: 1;
}
.barbecue-calculator-fat-selector
  .fat-selector-slider-container
  .fat-selector-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1.3vw;
  height: 1.3vw;
  background: #eb5b1c;
  cursor: pointer;
  border-radius: 50%;
}
.barbecue-calculator-fat-selector
  .fat-selector-slider-container
  .fat-selector-slider-texts {
  display: flex;
  justify-content: space-between;
  font-size: 1vw;
}

.submit-barbecue-form-button-container {
  margin-top: 3vw;
  display: flex;
  width: 100%;
  justify-content: center;
}
.submit-barbecue-form-button-container a {
  text-decoration: none;
  width: 100%;
}

@media screen and (max-width: 750px) {
  .submit-barbecue-form-button-title {
    font-size: 1.3rem;
  }
  .submit-barbecue-form-button-container .button-just-text {
    font-size: 0.8rem;
    padding: 0;
  }
  .barbecue-calculator-form-container.created-false {
    width: 100%;
  }
  .barbecue-calculator-form-container.created-true {
    display: none;
  }
  #barbecue-calculator-form-content .title {
    font-size: 1.4rem;
  }
  #barbecue-calculator-form-content .subtitle {
    font-size: 0.6rem;
  }
  .barbecue-calculator-fat-selector
    .fat-selector-slider-container
    .fat-selector-slider {
    opacity: 1;
    height: 1rem;
  }
  .barbecue-calculator-fat-selector
    .fat-selector-slider-container
    .fat-selector-slider::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
  }
  .barbecue-calculator-fat-selector
    .fat-selector-slider-container
    .fat-selector-slider-texts {
    display: flex;
    justify-content: space-between;
    font-size: 0.5rem;
  }

  .barbecue-calculator-people-quantity-selector .quantity-selector-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }
}
