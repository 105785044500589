.header-main {
  background-color: #ffffff;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 5px 5px 0px 5px;
  margin: 10px 40px 12px 40px;

  /*position: fixed;*/

  /*width: 100%;*/
}

.header-rows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  margin-top: 18px;
}

.header-menu {
  display: none !important;
}

@media only screen and (max-width: 750px) {
  .header-main {
    background-color: #ce3c25;
    padding: 0px 10px 15px 20px;
    margin: 0px;
  }

  .header-menu {
    display: flex !important;
    font-size: 30px;
    align-self: center;
    color: white;
    margin: 15px 20px 0px 0px;
  }
}
