.barbecue-cart-list-content {
  overflow-y: auto;
  max-height: 71vw;
}

@media screen and (max-width: 750px) {
  .barbecue-cart-list-content {
    max-height: none;
  }
}
