.simple-logo-main{
  text-align: center;
  cursor: default;
}

.simple-logo-image{
  width: 70%;
  min-width: 150px;
  max-width: 500px;
  margin: 5px 5px 15px 5px;
}

.simple-logo-image:hover{
  cursor: pointer;
}