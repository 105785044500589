.blend-hit {
  display: flex;
  background: #ededed;
  height: 150px;
  margin-bottom: 20px;
}

.blend-hit-box-image {
  width: 150px;
  height: 150px;
  background-color: #ffffff;
  border: 1px solid #ededed;
}

.blend-hit-content {
  display: flex;
  width: 65%;
  color: #000000;
}

.blend-hit-content:link {
  text-decoration: none;
  color: #000000;
}

.blend-hit-info {
  width: 40%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blend-hit-info > h5 {
  font-size: 18px;
  margin-bottom: 0;
}
.blend-hit-info > p {
  font-size: 14px;
  margin-bottom: 0;
  color: #00000091;
  word-wrap: break-word;
}
.blend-hit-info > span {
  font-size: 14px;
  margin-bottom: 0;
}

.blend-actions {
  width: 40%;
  display: flex;
  margin: auto 5px auto 0;
  justify-content: space-around;
}

.blend-edit-actions {
  width: 40%;
  display: flex;
  margin: auto 5px auto 0;
  justify-content: space-around;
}

.blend-actions > h5 {
  margin-top: 9px;
  align-self: center;
  width: 105px;
}
.blend-edit-actions > a:link {
  text-decoration: none;
}
.blend-hit-quantity {
  text-align: center;
  margin: auto;
  max-height: 40px;
  display: flex;
  flex-direction: column;
}
.blend-hit-quantity > span {
  color: #ce3c25;
  font-size: 10px;
}

.blend-template-edit {
  font-size: 18px;
  border: none;
  color: #000000;
  margin-top: auto;
}

.blend-template-edit:hover {
  color: #000000;
}

.blend-template-trash {
  font-size: 18px;
  border: none;
  background-color: #ededed;
}

.blend-template-trash:hover {
  cursor: pointer;
  outline: none;
}

/*LINE BLEND CONTENT*/
.blend-content-line {
  display: flex;
}

.item-info {
  width: 80%;
}

.blend-content-line > .blend-line-item-percentage {
  font-size: 48px;
}

@media only screen and (max-width: 1125px) {
  .blend-hit-info {
    width: 50%;
    min-width: 360px;
  }
  .blend-actions {
    margin: auto 10px auto 0;
    width: 43%;
  }

  .blend-hit-box-image {
    display: none;
  }

  .blend-hit-info > h5 {
    font-size: 15px;
  }
  .blend-hit-info > p {
    font-size: 12px;
  }
  .blend-hit-info > span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 960px) {
  .blend-actions {
    flex-direction: column-reverse;
    width: 33%;
    margin: auto 0;
  }

  .blend-hit-info {
    width: 70%;
    max-width: 345px;
    min-width: 310px;
  }

  .blend-actions > .blend-hit-quantity > .add-after-exists-button-main {
    margin: 6px auto;
  }
  .blend-actions > .blend-buyprice-main {
    margin: 15px auto 0 auto;
    width: auto;
  }
  .blend-actions > .blend-hit-quantity {
    flex-direction: column-reverse;
  }
  .blend-actions > .blend-hit-quantity > span {
    font-size: 8px;
  }
}

@media only screen and (max-width: 450px) {
  .blends-page-container {
    margin: 50px 5% !important;
  }
  .blend-hit {
    width: 100%;
  }
  .blend-hit-info {
    margin-left: 10px;
    width: 50%;
    min-width: 180px;
  }

  .blend-hit-info > h5 {
    font-size: 12px;
  }

  .blend-hit-info > p {
    font-size: 10px;
  }

  .blend-hit-info > span {
    font-size: 10px;
  }
  .blend-actions {
    margin: auto 0;
    width: 47%;
  }
  .blend-actions > .blend-hit-quantity > .add-after-exists-button-main {
    width: 100px;
    margin: 2px auto;
  }
  .blend-actions
    > .blend-hit-quantity
    > .add-after-exists-button-main
    > .add-after-exists-button-content {
    width: 50px;
    font-size: 9px;
    height: 25px;
  }
  .blend-actions
    > .blend-hit-quantity
    > .add-after-exists-button-main
    > button {
    font-size: 11px;
    height: 17px;
    width: 17px;
  }
  .blend-actions > .blend-hit-quantity {
    flex-direction: column-reverse;
  }
  .blend-actions > .blend-hit-quantity > span {
    font-size: 8px;
  }
}
