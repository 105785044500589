/* ALGOLIA DEFAULT SEARCH BOX STYLE*/
.ais-SearchBox {
  display: inline-block;
  position: relative;
  max-width: 300px;
  width: 100%;
  height: 46px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px;
}

.ais-SearchBox-form {
  width: 100%;
  height: 100%;
}

.ais-SearchBox-input {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #d4d8e3;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  display: inline-block;
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 1px solid #d4d8e3;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2);
  padding: 0;
  padding-right: 36px;
  padding-left: 46px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  content: '';
  appearance: none;
}

.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
  display: none;
}
.ais-SearchBox-input:hover,
.ais-SearchBox-input:active,
.ais-SearchBox-input:focus {
  box-shadow: none;
  outline: 0;
}
.ais-SearchBox-input::-webkit-input-placeholder {
  color: #9faab2;
  /*visibility: hidden;*/
  content: '???' !important;
}
.ais-SearchBox-input::-moz-placeholder {
  color: #9faab2;
  /*visibility: hidden;*/
  content: '???' !important;
}
.ais-SearchBox-input:-ms-input-placeholder {
  color: #9faab2;
  /*visibility: hidden;*/
  content: '???' !important;
}

.ais-SearchBox-input::placeholder {
  color: #9faab2;
  /*visibility: hidden;*/
  content: '???' !important;
}

.ais-SearchBox-submit {
  position: absolute;
  top: 0;
  right: inherit;
  left: 0;
  margin: 0;
  border: 0;
  border-radius: 4px 0 0 4px;
  background-color: rgba(255, 255, 255, 0);
  padding: 0;
  width: 46px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ais-SearchBox-submit::before {
  display: inline-block;
  margin-right: -4px;
  height: 100%;
  vertical-align: middle;
  content: '' 2;
}
.ais-SearchBox-submit:hover,
.ais-SearchBox-submit:active {
  cursor: pointer;
}
.ais-SearchBox-submit:focus {
  outline: 0;
}
.ais-SearchBox-submit svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  fill: #bfc7d8;
}

.ais-SearchBox-reset {
  display: none;
  position: absolute;
  top: 13px;
  right: 13px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: #bfc7d8;
}
.ais-SearchBox-reset:focus {
  outline: 0;
}
.ais-SearchBox-reset svg {
  display: block;
  margin: 0px 4px 4px 4px;
  width: 12px;
  height: 12px;
}

.ais-SearchBox-input:valid ~ .ais-SearchBox-reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
  animation-name: sbx-reset-in;
  -webkit-animation-duration: 250ms;
  animation-duration: 250ms;
}

/* ALGOLIA OVERRIDE BY US*/

.ais-SearchBox-submit {
  background-color: #ce3c25 !important;
  color: white !important;

  font-size: 20px !important;
  font-weight: bold !important;

  width: 50px;
  height: 37px;

  border-radius: 7px 0px 0px 7px !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.ais-SearchBox-submit svg {
  fill: white;
  height: 20px;
  width: 20px;
  stroke-width: 12px;
}

.use {
  stroke-width: 12px;
  font-weight: bold;
}

.ais-SearchBox-input {
  background-color: #f6f6f6;

  font-size: 14px !important;
  font-weight: 100 !important;
  text-indent: 15px !important;

  height: 37px;
  flex-grow: 1;

  border-width: 1px;
  border-radius: 0px 7px 7px 0px;
  border-style: none;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.ais-InstantSearch__root {
  height: 37px;
  width: 100%;
}

.ais-SearchBox {
  height: 37px;
  width: 100%;
  max-width: 100%;
}

.ais-Pagination-link.ais-Pagination-link--selected {
  color: #eb5b1e !important;
}

@media only screen and (max-width: 750px) {
  .ais-SearchBox-submit {
    background-color: #f6f6f6 !important;
    color: #ce3c25 !important;

    font-size: 20px !important;
    font-weight: bold !important;

    width: 50px;
    height: 37px;

    border: 1px solid #f6f6f6 !important;
    border-radius: 7px 0px 0px 7px !important;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }

  .ais-SearchBox-submit svg {
    fill: #ce3c25 !important;
    height: 20px;
    width: 20px;
    stroke-width: 12px;
  }

  .ais-SearchBox-input {
    background-color: #f6f6f6;

    font-size: 16px !important;
    font-weight: 100 !important;
    text-indent: 5px !important;

    height: 37px;
    flex-grow: 1;

    border-width: 1px;
    border-radius: 7px 7px 7px 7px;
    border-style: none;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
}
