.login-content{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0px 50px 0px;
}

.login-content-mobile{
  display: none;
}

.login-container{
  display: flex;
  flex-direction: row;
  justify-content: center;

}

.vertical-line{
  border-left: 1px solid #dddddd;
  width:2px;
  height: 400px;
  margin: 15px 30px;
}

.login-button-container{
  display:flex;
  flex-direction: column;

  margin: 15px 5px 0px 5px ;
}

@media only screen and (max-width: 550px /*SMALLER_MEDIA_SIZE*/) {
  .login-content{
    display: none;
  }

  .login-content-mobile{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0px 50px 0px;
  }


}