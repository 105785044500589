.credit-card-option-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.credit-card-option-main {
  background-color: #ededed;
  color: #474747;
  font-size: 14px;

  display: flex;
  align-items: center;

  width: 100%;
  max-width: 570px;
  height: 90px;
  margin-bottom: 10px;
  padding: 10px 0px;

  border: 1px solid #ededed;
}

.credit-card-option-main {
  cursor: pointer;
}

.credit-card-selected {
  background-color: #c0e8b7;
  border: 1px solid #2eb411;
}

.credit-card-expired {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  cursor: default;
}

.credit-card-option-image {
  width: 88px;
  height: 88px;
  margin-right: 20px;
}

.credit-card-option-information {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 280px;
}

.credit-card-option-information > p {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  margin: 0px;
  display: flex;
  justify-content: right;
}

.credit-card-option-information > p > span {
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 20px;
  color: #721c24;
  margin-left: 5px;
}

.credit-card-spinner {
  margin-right: 20px;
  margin-left: 45px;
}

.credit-card-option-trash {
  color: #8e8e8e;
  font-size: 25px;
  cursor: pointer;
  margin-right: 20px;
  margin-left: 50px;
}

.credit-card-option-trash:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .credit-card-option-information {
    max-width: 300px;
  }

  .credit-card-option-information > p {
    font-size: 12px;
    padding: 2px;
    white-space: nowrap;
  }

  .credit-card-option-trash {
    font-size: 25px;
    margin-right: 10px;
    margin-left: 150px;
  }
}

@media only screen and (max-width: 450px) {
  .credit-card-option-image {
    margin-right: 0px;
  }

  .credit-card-option-information > p {
    font-size: 12px;
  }

  .credit-card-option-trash {
    margin-left: 50px;
    margin-right: 25px;
  }
}

@media only screen and (max-width: 375px) {
  .credit-card-option-image {
    display: none;
  }

  .credit-card-option-trash {
    margin-right: 25px;
  }
}
