.my-account-page-container-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px 40px;
}

.my-account-page-container {
  width: 100%;
  max-width: 1000px;
  margin-left: 30px;
}

.my-account-page-pagination {
  background-color: #ededed;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  height: 30px;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

@media only screen and (max-width: 750px) {
  .my-account-page-container-content {
    display: flex;
    flex-direction: column;
    margin: 5px 10px;
  }

  .my-account-page-container {
    margin: 10px 0px 0px 0px;
  }
}
