/*.about-us-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}*/

.about-us-image {
  width: 100%;
  height: auto;
}

.about-us-text > h3 {
  color: #eb5b1e;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;

  margin-bottom: 15px;
}

.about-us-text {
  text-align: center;
  font-weight: 100;
  letter-spacing: 1px;
  width: 95%;
  max-width: 1200px;

  margin: 0 auto !important;
  padding: 0px 20px !important;
}

.about-us-line {
  width: 100%;
  border: 1px solid #eb5b1e;
}

.about-us-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0px;
}

.about-us-container > div {
  width: 40%;
}

.about-us-container > div:first-of-type {
  padding: 0px 30px 0px 0px;
}

.about-us-container > div > h3 {
  color: #eb5b1e;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;

  margin-bottom: 10px;
}

.about-us-values-container {
  display: flex;
  flex-direction: row;
}

.about-us-values-container > div:first-of-type {
  padding: 0px 20px 0px 0px;
}

.about-us-values-container > div > p {
  text-align: left;
  margin: 0px;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .about-us-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .about-us-container > div {
    width: 100%;
  }

  .about-us-container > div:first-of-type {
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (max-width: 450px) {
  .about-us-values-container {
    display: flex;
    flex-direction: column;
  }
}
