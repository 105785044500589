.item-list-container {
  display: flex;
  flex-direction: column;
}

.item-list-line {
  width: 100%;
  border-width: 2px;
}

.line-item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*min-height:75px;*/
  width: 450px;
}

.line-item-content > a > h3 {
  color: #212121 !important;
  font-size: 22px;
  font-weight: 700;
  margin: 0px;
}

.line-item-content > p {
  font-size: 16px;
  margin-bottom: 10px;
}

.line-item-content > a:hover {
  cursor: pointer;
  text-decoration: none;
}

.line-item-content > h3 {
  color: #626166;
  font-size: 20px;
  font-weight: 700;
  margin: 0px 15px 0px 0px;
}

.item-list-subcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 25px;
}

.line-item-price-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 70px;
  margin-left: 70px;
}

.line-item-price-content > p {
  color: #626166;
  font-size: 14px;
  margin: 5px 0px 0px 0px;
}

.line-item-price-content > h3 {
  color: #2eb411;
  font-size: 20px;
  font-weight: 700;
}

.item-list-trash {
  font-size: 18px;
  margin-left: 5px;
  border: none;
  background-color: #ffffff;
}

.item-list-trash:hover {
  cursor: pointer;
  outline: none;
}

.item-list-trash:focus {
  outline: none;
}

.line-item-price-container {
  display: flex;
}

.line-item-observation-main {
  width: 100%;
}

.item-portioning-size-text {
  color: #eb5b1e;
  font-weight: 500;
  font-size: 10pt;
}

.line-item-observation-main > form > textarea {
  height: 50px;
  float: left;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 1px;
  text-indent: 15px;
  border: none;
  background-color: #ededed;
  overflow: hidden;
  resize: none;
  width: 100%;
  margin-top: 10px;
}

.btn-save-observation {
  width: 200px;
  display: flex;
  float: right;
  margin: 15px 0px;
}

.item-portioning-edit {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.item-portioning-checkbox-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-portioning-checkbox-item label {
  margin: 0;
}

.item-slider-content {
  width: 50%;
  margin: auto 0;
}

.item-portioning-size-block {
  font-size: 14px;
  color: #4a4a4a;
  text-align: center;
  padding: 10px 20px;
  background-color: #ededed;
  width: 10vw;
}

.line-item-price-container-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.line-item-price-container-option > span {
  color: #626166;
  font-size: 10px;
  text-align: center;
}
.line-item-price-container-option.blend { 
  /* Hide the line-item-price-container-option if it is a blend*/
  visibility: hidden;
}
.item-portioning-size-quantity {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 19px;
  text-align: center;
}

@media only screen and (max-width: 750px) {
  .item-list-subcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px 5px;
  }

  .line-item-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .line-item-content > h3 {
    margin: 0px;
  }

  .line-item-price-content {
    margin-left: 50px;
    margin-right: 50px;
  }

  .item-list-trash {
    font-size: 22px;
    margin-left: 0;
  }

  .item-list-line {
    margin: 5px 0px;
  }

  .line-item-content > a > h3 {
    font-size: 18px;
  }

  .item-portioning-checkbox-item {
    font-size: 12px;
  }
  .item-portioning-size-block {
    padding: 5px 10px;
    width: 20vw;
  }
}

@media only screen and (max-width: 600px) {
  .item-list-subcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0px 5px;
  }

  .line-item-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;

    margin-bottom: 10px;
  }
  .item-list-trash {
    font-size: 22px;
  }

  .line-item-content > a > h3 {
    font-size: 16px !important;
  }

  .line-item-price-content > p {
    margin: 0px;
  }

  .line-item-price-content > h3 {
    font-size: 16px !important;
    margin: 0px;
  }

  .line-item-price-container {
    max-width: 100%;
  }

  .btn-save-observation {
    width: 100%;
  }
}
@media only screen and (max-width: 375px) {
  .line-item-price-content {
    margin-left: 40px;
    margin-right: 40px;
  }
}
@media only screen and (max-width: 360px) {
  .line-item-price-content {
    margin-left: 30px;
    margin-right: 30px;
  }
}
