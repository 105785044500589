.past-order-title {
  letter-spacing: 1px;
  font-size: 26px;
}

.past-order-details {
  margin-top: 10px;
  font-size: 18px;
  letter-spacing: 1px;
  color: #4f4f4f;
  font-weight: 100;
  word-spacing: 2px;
}

.past-order-information {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}

.past-order-information-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
}

.past-order-information-container-rigth {
  display: flex;
  flex-direction: column;
  justify-content: initial;
  width: 45%;
}

.past-order-information-sub-container {
  margin-bottom: 15px;
}

.past-order-information-sub-container > h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
}

.past-order-information-sub-container > p {
  font-size: 16px;
  color: #4f4f4f;
  font-weight: 100;
  margin: 0px;
  letter-spacing: 1px;
}

.past-order-information-payment-method > p {
  font-size: 16px;
  color: #4f4f4f;
  font-weight: 100;
  margin: 0px;
  letter-spacing: 1px;
}

.past-order-copy-button {
  cursor: pointer;
  background-color: #eb5b1e;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  border: none;
  border-radius: 3px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 70%;
  height: 30px;
}

.past-order-copy-button:hover {
  background-color: #d6541c;
}

.past-order-copy-button:focus {
  outline: none;
}

.see-nfe-button {
  margin-top: 10px;
  cursor: pointer;
  background-color: #eb5b1e;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 3px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 70%;
  height: 30px;
}

.see-nfe-button > i {
  margin-right: 5px;
  color: #ffffff;
}

.see-nfe-button:hover {
  background-color: #d6541c;
  text-decoration: none;
  color: #ffffff;
}

.see-nfe-button:focus {
  outline: none;
  text-decoration: none;
  color: #ffffff;
}

@media only screen and (max-width: 500px) {
  .past-order-copy-button {
    width: 100%;
  }
  .see-nfe-button {
    width: 100%;
  }
}
