.logged-out-options-main {
  font-weight: 600;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  width: 190px;
  margin: 0px 5px 0px 15px;
}

.logged-out-options-register {
  background-color: #ce3c25 !important;
  color: #ffffff;
  letter-spacing: 1px;

  height: 25px;

  border-radius: 7px;
  padding: 0px 5px;
}

.logged-out-options-register:hover {
  text-decoration: none;
  color: #ffffff;
}

.logged-out-options-login {
  background-color: #ffffff !important;
  color: #ce3c25;
}

.logged-out-options-login:hover {
  text-decoration: none;
  color: #ce3c25;
}

@media only screen and (max-width: 1100px) {
  .logged-out-options-register {
    padding: 3px 5px;
  }

  .logged-out-options-main {
    font-size: 14px;
  }

  .logged-out-options-main {
    width: 190px;
    margin: 0px 5px 0px 5px;
  }
}

@media only screen and (max-width: 750px) {
  .logged-out-options-main {
    display: none;
  }
}
