.cart-page-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 30px 30px 30px;
}

.cart-list-line {
  width: 100%;
  max-width: 1500px;
  border-width: 2px;
}

.cart-list-price {
  width: 100%;
}

.cart-list-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cart-list-button:hover {
  cursor: pointer;
}

.cart-list-button-disabled:hover {
  cursor: default;
}

.cart-list-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ce3c25;
  color: #fff;
  border-radius: 2px;
  width: 200px;
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
}

.cart-list-btn-disabled {
  background-color: #a5a5a5;
  pointer-events: none;
}

.cart-list-btn:hover {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}

.cart-page-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 50px;
}

.cart-page-empty > h3 {
  font-size: 25px;
  font-weight: 100;
  letter-spacing: 1px;
  margin: 0px 5px 30px 5px;
  text-align: center;
}

.cart-page-empty > img {
  max-width: 250px;
  height: auto;
  margin-bottom: 60px;
}

@media only screen and (max-width: 750px /*SMALL_MEDIA_SIZE*/) {
  .cart-page-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 5px 30px 5px;
  }
}
