.forgot-password-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.forgot-password-box {
  text-align: center;
  max-width: 300px;
  margin-bottom: 50px;
}

.forgot-password-box > h3 {
  color: #292b2c;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0px 0px 10px 0px;
}

.forgot-password-image {
  width: 120px;
  height: auto;
}

@media only screen and (max-width: 750px) {
  .forgot-password-box {
    margin: 0px 5px 50px 5px;
  }
}
