.option-box-main {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;

  border: 1px solid #b7b7b7;
  border-radius: 2px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  width: 160px;
  height: 55px;
  padding: 3px;
  margin-right: 10px;
  margin-bottom: 10px;

  cursor: pointer;
}

.option-box-text {
  color: #4a4a4a;
}

.option-box-main.option-box-selected {
  border: 1px solid #eb5b1e;
}

.option-box-image {
  max-width: 40px;
  max-height: 40px;
}
