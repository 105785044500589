.main-product-content-main{
  margin: 0px 40px 20px 40px;
}

.main-product-container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

@media only screen and (max-width: 750px /*SMALL_MEDIA_SIZE*/) {
  .main-product-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}