.barbecue-cart-container {
  width: 50%;
  background-color: #eb5b1c;
}
.barbecue-cart-image {
  width: 100%;
  height: auto;
}
.barbecue-cart-line {
  width: 80%;
  height: 10px;
  margin-top: 15px;
  border-radius: 4px;
  background-color: #fff;
}
.barbecue-cart-content {
  margin: 50px 0 0 50px;
}
.barbecue-cart-container-text {
  width: 70%;
  color: #fff;
}
.barbecue-cart-list-container {
  background-color: #fff;
  padding-bottom: 15px;
}
.barbecue-cart-info {
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  align-items: center;
  text-align: center;
}
.barbecue-cart-info .line {
  height: 7px;
  width: calc(100% - 30px);
  margin: 10px 0 15px 0;
  background: #b7b7b7;
}
.barbecue-cart-info .price-per-person {
  display: flex;
}
.barbecue-cart-info .price-per-person .price {
  margin-left: 5px;
  color: green;
}
.barbecue-cart-info .total-price .price {
  color: #eb5b1c;
  font-weight: bold;
}
.barbecue-cart-container-text.title {
  font-family: QUARTZO-Light;
  font-size: 3vw;
  line-height: 95%;
}
.barbecue-cart-container .loading {
  color: #fff;
  display: grid;
  width: 100%;
  height: 100%;
  place-items: center;
}
.barbecue-cart-content.barbecue-created {
  margin: 0;
  width: 80%;
  height: 80%;
  margin: 30px auto;
}
.barbecue-cart-content .barbecue {
  height: 100%;
  padding: 10px 0 30px 0;
}
.barbecue-cart-content .barbecue .barbecue-title {
  text-align: center;
  margin-bottom: 20px;
  font-family: QUARTZO-Light;
  color: #fff;
  font-size: 3vw;
}
.barbecue-add-all-to-cart-button {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: center;
}
.barbecue-back-button {
  margin-top: 25px;
  display: none;
  justify-content: center;
  margin-inline: auto;
}
@media screen and (max-width: 750px) {
  .barbecue-cart-container.created-false {
    display: none;
  }
  .barbecue-cart-info {
    font-size: 0.6rem;
  }
  .barbecue-cart-container.created-true {
    width: 100%;
  }
  .barbecue-title {
    font-size: 1.4rem !important;
  }
  .barbecue-cart-container.loading-container.created-true {
    height: 50vh;
  }
  .barbecue-back-button {
    display: flex;
  }
  .barbecue-cart-container .button-just-text {
    padding: 0;
    font-size: 0.8rem;
  }
}
