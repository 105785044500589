.delivery-picker-main {
  display: flex;
  justify-content: center;
  margin: 0px 15px;
}

.delivery-picker-scroller {
  width: 100%;
  max-width: 500px;
}

.slick-vertical {		
  display: flex;		
  flex-direction: column;		
}		
    
.slick-vertical > .slick-list {		
  width: 100%;		
  height: 350px !important;		
}		
  
.slick-vertical > .slick-list > .slick-track > .slick-slide {		
  width: 100% !important;		
}

.delivery-picker-arrow:hover {
  cursor: pointer;
}

.slick-arrow.slick-prev.slick-disabled
  > .delivery-picker-arrow-container
  > .delivery-picker-arrow {
  color: #bdbdbd;
}

.slick-arrow.slick-prev.slick-disabled
  > .delivery-picker-arrow-container
  > .delivery-picker-arrow:hover {
  cursor: default;
}

.slick-arrow.slick-next.slick-disabled
  > .delivery-picker-arrow-container
  > .delivery-picker-arrow {
  color: #bdbdbd;
}

.slick-arrow.slick-next.slick-disabled
  > .delivery-picker-arrow-container
  > .delivery-picker-arrow:hover {
  cursor: default;
}

@media only screen and (max-width: 370px) {
  .delivery-picker-main {
    display: flex;
    justify-content: center;
    margin: 0px;
  }
}
