.terms-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.terms-main-text{
  margin: 30px 150px;
  font-size: 12px;
  text-align: justify;
  letter-spacing: 1px;

  max-width: 1000px;
}

@media only screen and (max-width: 750px) {
  .terms-main-text{
    margin-left: 10%;
    margin-right: 10%;
    letter-spacing: 0px;
  }
}