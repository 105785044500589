.pane-title-main{
  background-color: #ededed;
  color: #212121;

  display:flex;
  flex-direction: column;
  /*justify-content: space-between;
  align-items: center;*/
  min-height:40px;
  width: 100%;

  padding: 5px 20px;
  margin-bottom: 15px;
}

.pane-title{
  width: 100%;
  margin-top: 5px;
}

.pane-title-details{
  color: #555555;
  font-size: 14px;
  margin-left: 18px;
}

.pane-arrow-up {
  width: 0; 
  height: 0; 
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  
  border-bottom: 7px solid #212121;
  margin-left: 10px;
}

.pane-arrow-down {
  width: 0; 
  height: 0; 
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  
  border-top: 7px solid #212121;
  margin-left: 10px;
}

.pane-arrow-up:hover{
  cursor: pointer;
}

.pane-arrow-down:hover{
  cursor: pointer;
}

@media only screen and (max-width: 500px /*SMALLER_MEDIA_SIZE*/) {
  .pane-title{
    margin-top: 5px;
   font-size: 14px;
  }

}

