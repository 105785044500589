.hamburger-menu {
  position: absolute;
  left: 0px;

  width: 100%;
  height: calc(
    100% - 295px
  ); /* Minus the height of the header and the footer since element is relative to body */
  z-index: 1000;
}

.hamburger-menu-outer {
  position: absolute;
  /*top: 74px;
  left: 0px;*/

  width: 100%;
  height: 100%;

  background-color: #f8f8f8;
  opacity: 0.7;
}

.hamburger-menu-main {
  position: absolute;
  /*top: 74px;
  left: 0px;*/

  background-color: #ffffff;
  color: #212121;
  border: 1px solid #d5d5d5;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;

  width: 100%;
  max-width: 350px;
  height: 340px;
  padding: 5px 5px;

  z-index: 100;
}

.hamburger-menu-main > h5 {
  color: #767676;
  font-size: 14px;
  margin: 5px 0px 0px 0px;
}

.hamburger-menu-main > h4 {
  color: #212121;
  font-size: 14px;
  margin: 0px;
}

.hamburger-menu-main > a {
  background-color: #ededed;
  color: #212121;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 1px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 35px;
}

.hamburger-menu-main > a:hover {
  text-decoration: none;
  color: #212121;
}

.hamburger-menu-main > a:focus {
  text-decoration: none;
}

.hamburger-menu-main > p {
  margin: 0px;
}

a.hamburger-menu-login {
  background-color: #ce3c25 !important;
  color: #fff !important;
}

.hamburger-menu-login > a {
  color: #fff !important;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 1px;
}

.hamburger-menu-telephone-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.hamburger-menu-telephone-image {
  height: 30px;
  width: auto;
  margin: 0px 15px 0px 5px;
}

.hamburger-menu-standard-text {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #000000;
  margin: 0px;
}

.hamburger-menu-standard-text:hover {
  color: #000;
  text-decoration: none;
}

.hamburger-menu-standard-text:focus {
  color: #000;
  text-decoration: none;
}

.hamburger-menu-standard-text > span {
  font-size: 14px;
}
