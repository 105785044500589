.cart-preview-main {
  color: #212121;

  display: flex;
  flex-direction: column;

  margin-left: 30px;
  width: 310px;
  /*height:400px;*/
}

.cart-preview-main > h4 {
  background-color: #ce3c25;
  color: #fff;

  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 45px;
  margin: 0px;
}

.cart-preview-line-items {
  background-color: #F6F6F6;
  font-size: 12px;

  padding: 15px 0px 0px 15px;
}

.cart-preview-line > p {
  margin-bottom: 0px;
}

.cart-preview-line-name {
  font-weight: 800;
  width: 150px;
}

.cart-preview-line-quantity {
  width: 60px;
}

.cart-preview-line-price {
  width: 70px;
}

.cart-preview-line {
  display: flex;
  justify-content: space-between;
  /*align-items: center;*/

  margin-bottom: 20px;
}

.cart-preview-totals {
  background-color: #ededed;
  font-size: 14px;

  padding: 5px 10px;
}

.cart-preview-totals > p {
  margin-bottom: 5px;
}

.cart-preview-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cart-preview-button:hover {
  cursor: pointer;
}

.cart-preview-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #ce3c25;
  color: #fff;
  border: 0px solid #ce3c25;

  width: 310px;
  height: 45px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
}

.cart-preview-button-sub-container {
  display: flex;
  flex-direction: column;
  align-self: center;

  width: 100%;
}

.cart-preview-btn-disabled {
  background-color: gray;
}

.cart-preview-btn:hover {
  cursor: pointer;
}

.cart-preview-btn-disabled:hover {
  cursor: auto;
}

.cart-preview-price-text {
  font-size: 10px;
  text-align: justify;
  width: 310px;
  padding: 8px;

  margin-bottom: 30px;
}

@media only screen and (max-width: 950px) {
  .cart-preview-main {
    margin-left: 0px;
    width: 100%;
    height: 100%;
    position: static;
  }

  .cart-preview-btn {
    width: 100%;
  }

  .cart-preview-price-text {
    width: 100%;
  }
}
