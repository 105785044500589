.barbecue-quantity-selector {
  padding: 10px 15px;
  background-color: #f0f0f0;
  border-radius: 2px;
  text-align: center;
}
.selector-quantity {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.barbecue-quantity.fas.fa-sort-up,
.barbecue-quantity.fas.fa-sort-down {
  font-style: normal;
  font-size: 30px;
  align-items: center;
}
.barbecue-quantity.fas.fa-sort-up {
  transform: translate(0, 15%);
}
.barbecue-quantity.fas.fa-sort-down {
  transform: translate(0, -15%);
}
.barbecue-quantity.fas.fa-sort-up {
  margin-left: 10px;
}
.barbecue-quantity.fas.fa-sort-down {
  margin-right: 10px;
}
.adult-quantity-container,
.child-quantity-container {
  width: 45px;
  height: 30px;
  color: #000;
  border: 2px solid #eb5b1c;
  background-color: #fff;
  border-radius: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.barbecue-quantity-button-up,
.barbecue-quantity-button-down {
  background: inherit;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.barbecue-quantity-button-up:focus,
.barbecue-quantity-button-down:focus {
  outline: none;
  box-shadow: none;
}
.barbecue-quantity-selector .selector-quantity-text {
  font-size: 14px;
}
@media screen and (max-width: 750px) {
  .adult-quantity-container,
  .child-quantity-container {
    width: 45px;
    height: 30px;
  }
  .barbecue-quantity-selector .selector-quantity-text {
    font-size: 0.7rem;
  }
  .barbecue-quantity.fas.fa-sort-up {
    margin-left: 5px;
  }
  .barbecue-quantity.fas.fa-sort-down {
    margin-right: 5px;
  }
}
