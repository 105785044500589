.accompaniments-list-container {
  padding-top: 2vw;
  display: grid;
  gap: 2vw;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
}
.accompaniment-list-loading {
  display: flex;
  padding: 40px 0 20px 0;
  font-size: 1.5vw;
  color: #b7b7b7;
  justify-content: center;
}
@media screen and (max-width: 400px) {
  .accompaniments-list-container {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 750px) {
  .accompaniment-list-loading {
    font-size: 10px;
  }
}
