.alert-container{
  width: 100%;
  height: 30px;
}

.alert-main{
  color: #fff;
  background-color: #ce3c25;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 0.5px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 20px;
  padding: 2px 10px;

  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 750px /*SMALL_MEDIA_SIZE*/) {
  .alert-container{
    width: 100%;
    height: 40px;
  }

  .alert-main{
    font-size: 12px;
  }
}

@media only screen and (max-width: 450px /*SMALL_MEDIA_SIZE*/) {
  .alert-container{
    background-color: #ce3c25;
    height: 70px;
  }

  .alert-main{
    font-size: 12px;
  }
}

@media only screen and (max-width: 450px /*SMALL_MEDIA_SIZE*/) {
  .alert-container{
    height: 70px;
  }
}

@media only screen and (max-width: 350px /*SMALL_MEDIA_SIZE*/) {
  .alert-container{
    height: 90px;
  }
}

@media only screen and (max-width: 300px /*SMALL_MEDIA_SIZE*/) {
  .alert-container{
    height: 150px;
  }
}