.mini-color-row-main{
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-indent: 10px;

  border-radius: 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
}

.mini-color-row-red {
  background-color: #ce3c25;
}

.mini-color-row-black {
  background-color: #212121;
}

.mini-color-row-grey {
  background-color: #ededed;
  color: #212121;
}