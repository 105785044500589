.product-price-main {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-around;
  height: 30px;
}

.product-price-promotion > h6 {
  display: inline !important;
}

.product-price-promotion > h5 {
  color: #2eb411 !important;
}

.product-price-main > h6 {
  display: none;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  text-decoration: line-through;
  color: #757575;
}

.product-price-main > h5 {
  color: #757575;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0px;
}
