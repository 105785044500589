.last-order-info-header {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.last-order-info-header > h3 {
  color: black;
  font-size: 18px;
  font-weight: 300;
  text-align: center;

  display: inline;
  white-space: nowrap;
  margin: 0px 15px;
}

.last-order-info-header > h3 > a {
  color: black;
}

.last-order-info-header > h3 > a:hover {
  text-decoration: none !important;
}

.last-order-info-header > h3 > a:focus {
  text-decoration: none !important;
}

.last-order-info-container {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px auto;
}

.last-order-info-container h2 {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}
