.add-after-exists-button-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  position: relative;
}

.add-after-exists-loader {
  position: absolute;
  top: 8px;
  right: -25px;
  border: 0px solid #fff;
}

.add-after-exists-button-main > .add-after-exists-button-content {
  font-size: 12px;

  border: 1px solid #b7b7b7;
  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 65px;
  height: 30px;
  margin: 0px 5px;
}

.add-after-exists-button-main > button {
  background-color: #eb5b1e !important;
  color: #fff !important;
  font-size: 14px;
  text-align: center;

  border: 0px solid #fff;
  border-radius: 12px !important;
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 1);

  height: 22px;
  width: 22px;
  padding: 0px 0px 0px 0px !important;
}

.add-after-exists-button-main > button:hover {
  cursor: pointer;
}

.add-after-exists-button-main > button:disabled {
  cursor: default;
  background-color: #b7b7b7 !important;
}
