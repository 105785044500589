.product-sqaure-main {
  color: #212121;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 340px;
  width: 256px;

  margin: 0px 5px 0px 0px;
}

.product-square-button {
  margin: 7px 0px 0px 0px;
}

.product-square-image {
  height: 150px;
  width: 150px;
}

.product-square-image:hover {
  cursor: pointer;
}

.product-square-title {
  color: #212121;
  font-size: 20px;
  font-weight: 700;
  height: 45px;
  margin: 8px 0px 0px 0px;
}

.product-square-link:hover {
  cursor: pointer;
  text-decoration: none;
}

.product-sqaure-main > h4 {
  font-size: 16px;
  font-weight: 200;
  margin: 5px 0px 8px 0px;
  height: 17px;
  width: 90%;
}

@media only screen and (max-width: 1100px) {
  .product-sqaure-main {
    height: 320px;
    width: 250px;
  }

  .product-square-title {
    font-size: 18px;
    display: flex;
    align-items: center;
    height: 55px;
  }
}

@media only screen and (max-width: 500px) {
  .product-sqaure-main {
    height: 320px;
    width: 168px;
  }

  .product-square-title {
    font-size: 16px;
    height: 20px;
    margin: 0 0 10px 0;
  }
}
