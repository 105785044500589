.cart-indicator-main{
  height:25px;
}

.cart-indicator-link:hover{
  text-decoration: none;
}

.cart-indicator-main>a:hover{
  text-decoration: none;
}

.cart-indicator-main>a:focus{
  text-decoration: none;
}