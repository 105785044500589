.video {
  width: 787px;
  height: 447px;
  border: 0;
}

/*.video:hover{
  cursor: pointer;
}*/

.video-player-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background: radial-gradient(#e9e9e9 40%, #d3d3d3 20%, #7F7F7F 20%) ;*/
  /*background: radial-gradient( #f7f7f7 5%, #f4f4f4 30%, #d2d2d2 50%, #A5A5A5 90%)*/
}

@media only screen and (max-width: 780px /*SMALL_MEDIA_SIZE*/) {
  .video {
    width: 525px;
    height: 300px;
  }
}

@media only screen and (max-width: 530px /*SMALL_MEDIA_SIZE*/) {
  .video {
    width: 350px;
    height: 200px;
  }
}

@media only screen and (max-width: 340px /*SMALL_MEDIA_SIZE*/) {
  .video {
    width: 263px;
    height: 150px;
  }
}
