.nav-list-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-list-main > a {
  color: #292b2c !important;
  font-size: 14px;
  font-weight: 300;
  margin-right: 15px;
}

.nav-list-main > h3 > a {
  color: black !important;
  font-size: 16px;
  font-weight: 700;
  margin-right: 15px;
}

.nav-list-main > a:hover {
  text-decoration: none;
  cursor: pointer;
}

.nav-list-main > h3 > a:hover {
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 1125px /*SUBDEPARTMENTS_DISAPPEAR_SIZE*/) {
  .nav-list-main > a {
    display: none;
  }
}
@media only screen and (max-width: 950px /*BARBECUE_CALCULATOR_DISAPPEAR_SIZE*/) {
  .nav-list-barbecue-calculator {
    display: none;
  }
}
@media only screen and (max-width: 770px) {
  .nav-list-main > h3 > a {
    font-size: 15px;
  }
}
