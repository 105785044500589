.search-page-menu {
  width: 250px;
  margin-top: 20px;
  padding: 0px 30px;
}

.ais-RefinementList-count {
  visibility: hidden !important;
}

.ais-RefinementList-label {
  display: flex;
  align-items: center;

  font-size: 14px !important;
  margin-left: 10px;
  box-shadow: 0px;
}

.ais-RefinementList-checkbox {
  width: 10px;
  height: 10px;
  border-radius: 0px !important;
  margin-right: 5px !important;
}

.ais-RefinementList-checkbox:hover {
  cursor: pointer;
}

.ais-Panel--noRefinement {
  display: none;
}

.ais-Toggle {
  font-size: 14px !important;
  box-shadow: 0px;
  display: flex;
  align-items: center;

  margin-left: 10px;
}

.ais-ToggleRefinement-checkbox {
  width: 10px;
  height: 10px;
  border-radius: 0px !important;
  margin-right: 5px !important;
}

.ais-ToggleRefinement-checkbox:hover {
  cursor: pointer;
}

.ais-ToggleRefinement-label {
  display: flex;
  align-items: center;

  font-size: 14px !important;
  margin-left: 10px;
  box-shadow: 0px;
}

.ais-ClearRefinements-button--disabled {
  visibility: hidden;
}

.ais-ClearRefinements-button:hover {
  cursor: pointer;
}

.ais-ClearRefinements-button {
  background-color: #fff;
  color: #3f3f3f;
  font-size: 14px;
  text-align: center;

  border-radius: 3px;
  border: 1px solid #3f3f3f !important;

  height: 25px;
  width: 120px;
}

@media only screen and (max-width: 750px /*SMALL_MEDIA_SIZE*/) {
  .search-page-menu {
    display: none;
  }
}
