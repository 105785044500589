.payment-pane-content {
  margin: 0px 10px;
}

.payment-pane-payment-information-title {
  font-size: 18px;
  letter-spacing: 1px;
}

.payment-pane-checkout-continue-button {
  display: flex;
  justify-content: center;

  width: 100%;
}

.payment-pane-methods-message {
  font-size: 14px;
  padding: 15px 0px;
}

.payment-pane-discount-coupon-apply-information {
  font-size: 14px;
  margin: 0;
  color: #2eb411;
}

.payment-pane-discount-coupon-apply-error {
  color: #ce3c25 !important;
}

.payment-pane-discount-coupon-information {
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
}

.payment-pane-select-coupon {
  margin-bottom: 15px;
}
