.stamp-main{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 290px;
}

.stamp-image{
    height: 170px;
    width: 149px;
}

@media only screen and (max-width: 750px /*SMALL_MEDIA_SIZE*/) {
  .stamp-main{
    display: flex;
    flex-direction: row;
    width: 180px;
  }
}