.out-of-stock-notice-main {
  margin-top: 5px;
  background-color: #ededed;
  color: #212121;
  width: 100%;
  min-height: 30px;
  border-radius: 4px;
  text-align: center;
  letter-spacing: 1px;
  padding: 2px 5px 0px 5px;
  font-size: 16px;
}

.out-of-stock-notice-main > i {
  margin-right: 5px;
}

@media only screen and (max-width: 500px) {
  .out-of-stock-notice-main {
    font-size: 14px !important;
    padding: 4px 5px 0px 5px;
  }
}
