.order-tracker-main {
  display: flex;
  flex-direction: column;
}

.order-tracker-info-header {
  margin-bottom: 0px;
}

.order-progress {
  counter-reset: step;
  margin: 10px 0 10px 0;
  padding-left: 0px;
}

.order-progress li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #4f4f4f;
}

.order-progress li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}

.order-progress li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.order-progress li:first-child:after {
  content: none;
}

.order-progress li.active {
  color: #eb5b1e;
}

.order-progress li.active:before {
  border-color: #eb5b1e;
}

.order-progress li.active + li:after {
  background-color: #eb5b1e;
}

li.order-progress-last-one-valid-animation-line + li:after {
  animation-name: order-progress-last-line;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

li.order-progress-last-one-valid-animation-marker:before {
  animation-name: order-progress-last-marker;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

li.order-progress-last-one-valid-animation-marker {
  animation-name: order-progress-last-marker;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

@keyframes order-progress-last-line {
  0% {
    background-color: #eb5b1e;
  }
  50% {
    background-color: #fff;
  }
  100% {
    background-color: #eb5b1e;
  }
}

@keyframes order-progress-last-marker {
  0% {
    border-color: #eb5b1e;
    color: #eb5b1e;
  }
  50% {
    border-color: #4f4f4f;
    color: #4f4f4f;
  }
  100% {
    border-color: #eb5b1e;
    color: #eb5b1e;
  }
}

.order-tracker-shipment-information {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}

.order-tracker-shipment-information-map-image {
  width: 60%;
  height: 100%;
  margin-right: 10px;
}

.order-tracker-shipment-information-map-image-only-in-page {
  width: 100%;
  margin-right: 10px;
}

.order-tracker-shipment-information-driver {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.order-tracker-shipment-information-driver-header {
  color: grey;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
}

.order-tracker-shipment-information-driver > div > p {
  margin: 1px 0;
}

@media only screen and (max-width: 770px) {
  .order-tracker-shipment-information {
    display: flex;
    flex-direction: column;
  }

  .order-tracker-shipment-information-map-image {
    width: 100%;
    margin-right: 0px;
  }

  .order-tracker-shipment-information-driver > div > p {
    display: inline-block;
  }
}
