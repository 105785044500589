.my-account-information{
  display: flex;
  flex-direction: column;
}

.my-account-information>h3 {
  font-size: 20px;
  font-weight: 100;
}

.my-account-information-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.my-account-information-medium-content{
  width: 49.5%;
}

.my-account-information-medium-input{
  background-color: #F6F6F6;
  font-size:14px;
  font-weight: 200;
  letter-spacing: 1px;
  text-indent: 15px;
  border: 0px solid #fff;
  width: 100%;
  height: 35px;

  margin-bottom: 10px;
}

.my-account-information-medium-error{
  width: 100%;
}

.my-account-information-submit-content{
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 100%;
  margin-top: 5px;
}

.my-account-information-submit-button{
  width: 250px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 750px /*SMALL_MEDIA_SIZE*/) {
  .my-account-information-content{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .my-account-information-medium-content{
    width: 100%;
  }

  .my-account-information-medium-input{
    font-size:16px;
  }
}