.blend-buyprice-main {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 190px;
  height: 48px;
}

.blend-buyprice-main > .blend-buy-price-single {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 150px;
}

.blend-buyprice-show-list-price > h6 {
  display: inline !important;
}

.blend-buyprice-main > .blend-buy-price-single > h6 {
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  text-decoration: line-through;
  color: #757575;
  display: none;
  margin: 2px 0px;
}

.blend-buyprice-estimated-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 150px;
  height: 48px;
}

.blend-buyprice-estimated-value {
  font-size: 26px;
  margin: 0px;
}

.blend-buyprice-value {
  color: #8e8e8e;
  font-size: 14px;
  margin: 0px;
}

@media only screen and (max-width: 750px) {
  .blend-buyprice-estimated-value {
    font-size: 20px;
  }

  .blend-buyprice-estimated-content {
    height: 40px;
  }

  .blend-buyprice-main {
    height: 40px;
  }

  .blend-buyprice-main > .blend-buy-price-single > h4 {
    font-size: 20px;
    margin: auto;
  }

  .blend-buyprice-show-list-price > h4 {
    font-size: 18px !important;
  }

  .blend-buyprice-main > .blend-buy-price-single > h6 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 450px) {
  .blend-buyprice-main {
    width: 100%;
  }
  .blend-buyprice-estimated-content {
    width: 100px;
  }
  .blend-buyprice-main > .blend-buy-price-single {
    width: 100px;
  }
  .blend-buyprice-estimated-value {
    font-size: 14px;
  }
  .blend-buyprice-value {
    font-size: 10px;
  }
  .blend-buyprice-main > .blend-buy-price-single > h4 {
    font-size: 14px;
  }
}
