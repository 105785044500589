.my-account-address-editor-main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  width: 100%;
  margin-top: 10px;
}

.my-account-address-editor-main > input::placeholder {
  font-size: 14px;
  letter-spacing: 1px;
}

.my-account-address-editor-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.my-account-address-editor-large-input {
  background-color: #f6f6f6;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 1px;
  text-indent: 15px;
  border: 0px solid #fff;
  width: 100%;
  height: 35px;
  margin-bottom: 5px;
}
.my-account-address-editor-medium-content {
  display: inline-flex;
  flex-direction: column-reverse;
  width: 66.3%;
}

.my-account-address-editor-medium-input {
  background-color: #f6f6f6;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 1px;
  text-indent: 15px;
  border: 0px solid #fff;
  width: 100%;
  height: 35px;
  margin-bottom: 5px;
  align-self: flex-end;
}

.my-account-address-editor-small-content {
  display: inline-flex;
  flex-direction: column-reverse;
  width: 32.5%;
}

.my-account-address-editor-small-input {
  background-color: #f6f6f6;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 1px;
  text-indent: 15px;
  border: 0px solid #fff;
  width: 100%;
  height: 35px;
  margin-bottom: 5px;
}

.my-account-address-editor-submit-content {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}

.my-account-address-editor-submit-button {
  width: 250px;
}

.my-account-address-editor-city-dropdown-container {
  background-color: #f6f6f6;
  width: 100%;
  height: 35px;
  margin-bottom: 5px;
}

.my-account-address-editor-city-dropdown {
  background-color: #f6f6f6;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 1px;
  border: none;
  border-radius: 0px;
  width: 100%;
  height: 35px;
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .my-account-address-editor-line {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .my-account-address-editor-city-dropdown {
    font-size: 16px;
  }

  .my-account-address-editor-main > input::placeholder {
    font-size: 16px;
    letter-spacing: 1px;
  }

  .my-account-address-editor-small-content {
    width: 100%;
  }

  .my-account-address-editor-medium-content {
    width: 100%;
  }

  .my-account-address-editor-small-input {
    font-size: 16px;
  }

  .my-account-address-editor-medium-input {
    font-size: 16px;
  }

  .my-account-address-editor-large-input {
    font-size: 16px;
  }
}
