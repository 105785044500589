.error-on-order-line {
  width: 100%;
  border: 1px solid #eb5b1e;
}

.error-on-order-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 2px 0px;
  padding: 2px;
}

.error-on-order-text > p {
  letter-spacing: 1px;
  font-weight: 100;
}

.error-on-order-text > span {
  font-weight: 600;
  color: #ce3c25;
}

.error-telephone-mobile {
  display: none;
}

.error-telephone {
  display: inline;
}

@media only screen and (max-width: 500px) {
  .error-on-order-text {
    font-size: 12px;
  }
  .error-telephone-mobile {
    display: inline;
  }

  .error-telephone {
    display: none;
  }
}
