/*.cart-change-main{
  visibility: hidden;
}*/

.cart-change-notice-close-icon {
  width: 99%;
  height: 15px;
  color: #292b2c;
  text-align: right;
  margin-top: -3px;
}

.cart-change-notice-close-icon > i {
  padding: 5px 5px 0px 5px;
}

.cart-change-notice-close-icon:hover {
  cursor: pointer;
}

.cart-change-notice-container {
  background-color: #fff;
  text-align: center;
  border: 1px solid #b7b7b7;
  border-radius: 2px;

  width: 350px;
  padding: 5px;
  z-index: 990;
  position: fixed;
  top: 80px;
  right: 30px;
}

.notice-to-top {
  position: fixed;
  top: 10px !important;
  right: 30px !important;
}

.cart-change-notice-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  min-height: 40px;
  margin-bottom: 8px;
}

.cart-change-notice-image {
  width: auto;
  max-width: 120px;
  height: 40px;
}

.cart-change-notice-content > h3 {
  font-size: 16px;
  font-weight: 700;
  width: 300px;
  margin: 0px;
}

.cart-change-notice-content > p {
  font-size: 16px;
  font-weight: 200;
  margin: 0px 10px 0px 0px;
  width: 100px;
}

.arrow-to-top {
  position: fixed;
  top: 0px !important;
  right: 50px !important;
}

.arrow-up {
  width: 15px;
  height: 15px;
  z-index: 999;
  background-color: #fff;
  position: fixed;
  top: 70px;
  right: 50px;
  margin: 3px 10px 0px 0px;
  border-left: 1px solid #b7b7b7;
  border-top: 1px solid #b7b7b7;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.cart-change-notice-link {
  font-size: 14px;
  margin-top: -15px;
}

@media only screen and (max-width: 750px) {
  .cart-change-notice-container {
    top: 55px;
    right: 5px;
  }

  .notice-to-top {
    right: 5px !important;
  }

  .arrow-up {
    top: 45px;
    right: 20px;
  }

  .arrow-to-top {
    right: 20px !important;
  }
}

@media only screen and (max-width: 360px) {
  .cart-change-notice-content {
    flex-direction: column;
    height: 100%;
  }

  .cart-change-notice-container {
    width: 95%;
  }

  .cart-change-notice-content > h3 {
    width: 95%;
  }
}
