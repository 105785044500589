.password-recovery-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.password-recovery-box {
  text-align: center;
  max-width: 300px;
  margin-bottom: 80px;
}

.password-recovery-box > h3 {
  color: #292b2c;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0px 0px 10px 0px;
}

.password-recovery-image {
  width: 190px;
  height: auto;
  margin-top: 10px;
}

@media only screen and (max-width: 750px) {
  .password-recovery-box {
    margin: 0px 5px 50px 5px;
  }
}
