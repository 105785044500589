.confirmation-page-main {
  color: #31cd25;

  display:flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  margin: 20px 0px 150px 0px;
}

.confirmation-page-content {
  position: relative;

  border: 1px solid #a5a5a5;
  border-radius: 2px;

  width: 90%;
  max-width: 700px;
  padding: 100px 0px 20px 0px;
}

.confirmation-page-text {
  font-size: 60px;
  text-align: center;

  width: 70%;
  max-width: 400px;

  position: absolute;
  top: 0;
  left: 0;
}

.confirmation-page-button-container > a {
  background-color: #2eb411;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 50%;
  max-width: 200px;
  height: 40px;
  padding: 0px 10px;
}

.confirmation-page-button {
  background-color: #212121 !important;
  margin-right: 5px;
}

.confirmation-page-button-container > a:hover {
  color: #fff;
  text-decoration: none;
}

.confirmation-page-button-container > a > h3 {
  font-size: 16px;
  letter-spacing: 1px;
  margin: 0px;
}

.confirmation-page-text > h3 {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0px;
  padding: 0px 10px;
}

.confirmation-page-button-container {
  display: flex;
  justify-content: space-around;
  margin: 0px 50px;
}

.confirmation-page-image {
  height: auto;
  width: 90%;
  min-height: 50px;
  max-width: 670px;

  margin-bottom: 15px;
}

.disclaimer-message-confirmation{
  color: #757575;
  font-size: 14px;
  min-height: 50px;
  max-width: 670px;
  text-align: justify;
  margin-top: 20px;
  font-family: 'Open Sans', sans-serif; 
}

@media only screen and (max-width: 750px) {
  .confirmation-page-button-container > a > h3 {
    font-size: 14px;
  }

  .confirmation-page-button-container {
    margin: 0px 10px;
  }

  .confirmation-page-text {
    margin-top: -10px;
  }

  .disclaimer-message-confirmation{
    padding: 0 30px;
  }
}

@media only screen and (max-width: 550px) {
  .confirmation-page-text {
    font-size: 35px;
    margin-top: 0px;
  }

  .confirmation-page-text > h3 {
    font-size: 18px;
  }

  .disclaimer-message-confirmation{
    padding: 0 20px;
  }
}

@media only screen and (max-width: 300px) {
  .confirmation-page-button-container > a > h3 {
    font-size: 12px;
  }

  .confirmation-page-text {
    font-size: 20px;
    margin-top: -5px;
  }

  .confirmation-page-text > h3 {
    font-size: 16px;
  }

  .disclaimer-message-confirmation{
    padding: 0 15px;
    font-size: 11px;
  }
}
