.bottomrow-main{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 5px;
    width: 100%;
}

.bottomrow-telphone-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 200px;
}

.bottomrow-telephone-image{
    height: 25px;
    width: auto;
    margin: 0px 5px;
}

.bottomrow-telephone-text{
   display: flex;
   flex-direction: column; 
}

.bottomrow-telephone-text > p{
    margin: 0px
}

.bottomrow-telephone-mini-text{
    font-size: 12px;
    font-weight: 200;
    color: #292b2c;
}

.bottomrow-telephone-standard-text{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #000000;
}

.bottomrow-telephone-standard-text:hover{
    color: #000;
}

.bottomrow-telephone-standard-text:focus{
    color: #000;
}

.bottomrow-telephone-standard-text>span{
    font-size: 14px;
}

@media only screen and (max-width: 750px /*SMALL_MEDIA_SIZE*/) {
  .bottomrow-main{
    display: none;
  }

}