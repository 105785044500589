.departments-index-main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.departments-index-flex-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;
  max-width: 1500px;
  margin: 10px 1px 10px 5px;
}

