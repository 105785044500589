.my-account-password-main {
  display: flex;
  flex-direction: column;
}

.my-account-password-main > h3 {
  font-size: 20px;
  font-weight: 100;
  margin-bottom: 10px;
}

.my-account-password-main > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-account-password-large-input {
  background-color: #f6f6f6;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 1px;
  text-indent: 15px;
  border: 0px solid #fff;
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
}

.my-account-password-submit-content {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 5px;
}

.my-account-password-submit-button {
  width: 250px;
}

.account-password-strength-text {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 200;
  float: right;
  line-height: 15px;
  color: #4a4a4a;
}

.account-password-strength-strong {
  color: #59b259;
  font-weight: bold;
}

.account-password-strength-medium {
  color: #e77600;
  font-weight: bold;
}

.account-password-strength-poor {
  color: #d0021b;
  font-weight: bold;
}

.my-account-password-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50%;
  align-items: center;
}

@media only screen and (max-width: 750px /*SMALL_MEDIA_SIZE*/) {
  .my-account-password-large-input {
    font-size: 16px;
    width: 100%;
  }
  .my-account-password-form {
    width: 100%;
  }
}
