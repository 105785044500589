.blends-page-banner-image {
  width: 100%;
}

.blends-page-container {
  margin: 50px 15%;
  display: flex;
  flex-direction: column;
}

.blends-page-no-blend-image {
  height: 150px;
}

.blends-page-no-blend {
  height: 150px;

  background: #dadada;
}

.blends-page-no-blend > span {
  margin-left: 50px;
  font-size: 18px;
}

.blends-page-caculator-button {
  width: 80%;
  height: 51px;
  margin: auto;
  text-align: center;

  background-color: #ce3c25;
  color: #ffffff;
}
.blends-page-caculator-button > div {
  text-align: center;
  position: relative;
  top: 25%;
}
.blends-page-caculator-button:hover {
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
}
