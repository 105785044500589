.far,
.fas {
  font-family: 'FontAwesome';
}

.notify-me-button {
  padding: 0;
  border-radius: 3px;

  border: none;
  margin: 0px;
  height: 40px;
  width: 150px;
  background-color: #c4c4c4;
  display: flex;
  margin-top: 8px;
  place-items: center;
  -webkit-font-smoothing: antialiased;
}

.notify-me-button .no-focus:focus,
.notify-me-button-clicked .no-focus:focus {
  outline: thin dotted;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: 0px;
}

.notify-me-button .darker-button,
.notify-me-button-clicked .darker-button {
  border-radius: 3px 0 0 3px;
  left: 0;
  height: 40px;
  width: 30px;
  background-color: #8e8e8e;
}

.notify-me-button .darker-button .icon,
.notify-me-button-clicked .darker-button .icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notify-me-button .notify-me-text,
.notify-me-button-clicked .notify-me-text {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 10px;
  font-weight: 400;
}

.notify-me-button .notify-me-text .notify-me-bold-text,
.notify-me-button-clicked .notify-me-text .notify-me-bold-text {
  font-size: 12px;
  font-weight: bold;
}

.notify-me-button-clicked {
  padding: 0;
  border-radius: 3px;

  border: none;
  margin: 0px;
  height: 40px;
  width: 150px;
  background-color: #c4c4c4;
  display: flex;
  margin-top: 8px;
  place-items: center;
  -webkit-font-smoothing: antialiased;
}

.notify-me-button.loading-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notify-me-button .loading-icon,
.notify-me-button-clicked .loading-icon {
  font-size: 23px;
}
