.barbecue-calculator-cut-type .cut-type-container {
  padding-top: 2vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2vw;
  justify-content: center;
  place-items: center;
}
@media screen and (max-width: 1200px) {
  .barbecue-calculator-cut-type .cut-type-container {
    margin: auto;
    grid-template-columns: 1fr;
    width: 60%;
  }
}
