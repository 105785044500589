.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #fff;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  white-space: nowrap;
  font-family: inherit;
}

.dropdown-arrow-container {
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dropdown-line {
  color: #c7c7c7;
  margin: 0px 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 35px;
  right: -50px;
  background-color: #fff;
  border: 1px solid #c7c7c7;
  font-size: 14px;
  width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #fff;
}

.logged-in-arrow-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;

  border-top: 7px solid #212121;
  margin-left: 5px;
}

@media only screen and (max-width: 750px) {
  .dropdown {
    display: none;
  }
}
