.checkbox {
  display: grid;
  cursor: pointer;
  grid-template-columns: -webkit-min-content auto;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  border: 1px solid #b7b7b7;
  border-radius: 2px;
  padding: 0 20px;
  height: 55px;
  align-items: center;
  font-size: 14.88px;
}
.checkbox.selected {
  border-color: #000;
}
.checkbox.selected .checkbox-text {
  color: #000;
}
.checkbox-text {
  color: #4a4a4a;
}
.checkbox-icon {
  display: inline-grid;
  width: 23px;
  height: 23px;
  border-radius: 0.25em;
  border: 0.1em solid #b7b7b7;
}
.checkbox-icon i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  transition: transform 0.1s ease-in 25ms;
  transform: scale(0);
  color: #eb5b1e;
  transform-origin: bottom left;
}

.checkbox-input {
  display: grid;
  grid-template-areas: 'checkbox';
}
.checkbox-input > * {
  grid-area: checkbox;
}
.checkbox-input input {
  opacity: 0;
  width: 1em;
  height: 1em;
}
.checkbox.selected .checkbox-icon {
  box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em #eb5b1c;
}

.checkbox-input.selected .checkbox-icon {
  border-color: #000;
}
.checkbox-input.selected .checkbox-icon i {
  transform: scale(1);
}
.checkbox-text {
  font-family: QUARTZO-Light;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.complementary-category-checkbox-input {
  cursor: pointer;
}
@media screen and (max-width: 750px) {
  .checkbox-text {
    font-size: 0.9rem;
  }
  .checkbox-icon i::before {
    position: absolute;
    margin-top: 2px;
  }
}
