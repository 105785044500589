.search-result-image-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  max-width: 200px;
  width: 90%;
  margin-right: 20px;
}

.search-result-image-main > a {
  width: 100%;
}

@media only screen and (max-width: 750px) {
  .search-result-control {
    width: 150px;
  }

  .search-result-image-main > a {
    min-width: 30px;
    max-width: 150px;
    width: 90%;
  }
}
@media only screen and (max-width: 550px) {
  .search-result-image-main {
    max-width: 70px;
  }
}
@media only screen and (max-width: 380px) {
  .search-result-control {
    width: 200px;
    display: flex;
    justify-content: center;
  }
  .search-result-image-main {
    width: 75%;
    max-width: 200px;
    margin: 0px 0px 5px 0px;
  }
}
