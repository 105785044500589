.order-line-item-main {
  background-color: #eb5b1e;
  color: #fff;

  font-size: 14px;
  font-weight: 100;
  letter-spacing: 1px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 3px;

  width: 100%;
  min-height: 35px;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.order-line-item-main:hover {
  cursor: pointer;
  color: #fff;
}

.order-line-item-main > p {
  margin-right: 15px;
  margin-bottom: 0px;
  text-align: center;
}

.order-line-order-number {
  text-align: left !important;
}

.order-line-order-status {
  text-align: right !important;
  text-transform: capitalize;
  width: 150px;
}

.order-line-item-div {
  display: flex;
}

.order-line-order-copy-button {
  cursor: pointer;
  background-color: #eb5b1e;
  color: #fff;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 1px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  border-radius: 3px;
  width: 5%;
  margin: 0 0 10px 5px;
}

.order-line-order-copy-button:focus {
  outline: none;
}

@media only screen and (max-width: 770px) {
  .order-line-item-main {
    font-size: 12px;
  }
  .order-line-order-copy-button {
    width: 8%;
  }
}

@media only screen and (max-width: 500px) {
  .order-line-item-main {
    flex-direction: column;
  }
  .order-line-order-copy-button {
    width: 15%;
  }
  .order-line-order-status {
    width: auto;
  }
}
