.buyprice-main {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 190px;
  height: 48px;
}

.buyprice-main > .buy-price-single {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.buyprice-main > .buy-price-single > h3 {
  font-size: 26px;
  font-weight: 700;
  margin: 0px;
}

.buyprice-show-list-price > h6 {
  display: inline !important;
}

.buyprice-main > .buy-price-single > h6 {
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  text-decoration: line-through;
  color: #757575;
  display: none;
  margin: 2px 0px;
}

.buyprice-estimated-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 150px;
  height: 48px;
}

.buyprice-estimated-value {
  font-size: 26px;
  margin: 0px;
}

.buyprice-value {
  color: #8e8e8e;
  font-size: 14px;
  margin: 0px;
}

@media only screen and (max-width: 750px) {
  .buyprice-estimated-value {
    font-size: 20px;
  }

  .buyprice-estimated-content {
    height: 40px;
  }

  .buyprice-main {
    height: 40px;
  }

  .buyprice-main > .buy-price-single > h3 {
    font-size: 26px;
  }

  .buyprice-show-list-price > h3 {
    font-size: 18px !important;
  }

  .buyprice-main > .buy-price-single > h6 {
    font-size: 12px;
  }
}
