.work-with-us-image{
  width: 100%;
  height: auto;
}

.work-with-us-text>h3{
  color: #eb5b1e;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;

  margin-bottom: 20px;
}

.work-with-us-text{
  text-align: center;
  font-weight: 100;
  letter-spacing: 1px;

  margin: 30px 20px;
}

.work-with-us-line{
  border: 1px solid #eb5b1e;
}