.view-line-main{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px 0px 0px;
}

.view-line-display{
  background-color: #eb5b1e;
  border-radius: 4px;

  height: 18px;
  width: 100%;

}