.checkout-continue-button-main{
  background-color: #eb5b1e;
  color: #fff;

  font-weight: 200;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 250px;
  height:40px;

  margin-bottom: 20px;
  margin-top: 10px;
}

.checkout-continue-button-main:hover{
  cursor: pointer;
}

.checkout-continue-disabled {
  background-color: #e69d92 !important;
}

.checkout-continue-disabled:hover{
  cursor: default !important;
}

.checkout-continue-button-main > .fa-spinner{
  margin-right: 10px;
}

@media only screen and (max-width: 500px /*SMALLER_MEDIA_SIZE*/) {
  .checkout-continue-button-main{
   font-size: 14px;
  }

}

@media only screen and (max-width: 370px /*SMALLER_MEDIA_SIZE*/) {
  .checkout-continue-button-main{
   font-size: 12px;
   width: 80%;
  }

}