.faq-hit-main {
  max-width: 900px;
  width: 100%;
  margin: 0px 0px 30px 0px;
}

.faq-hit-main > h3 {
  font-size: 22px;
  font-weight: 700;
  color: #eb5b1e;
  margin: 0px 0px 5px 0px;
}

.faq-hit-main > p {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: justify;
  margin: 0px;
}

@media only screen and (max-width: 350px) {
  .faq-hit-main > h3 {
    font-size: 20px;
  }

  .faq-hit-main > p {
    font-size: 13px;
  }
}
