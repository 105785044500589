.whatsapp-button-a:hover {
  text-decoration: none;
}

.whatsapp-button {
  background-color: #25d366;
  font-weight: 100;
  font-size: 15px;
  text-align: center;
  display: flex;
  border-radius: 20px;
  border: 0px solid #fff;
  box-shadow: #fff;
  cursor: pointer;
  height: 40px;
  margin: auto;
}

.whatsapp-button-content {
  display: flex;
  color: #ffffff;
  align-items: center;
  vertical-align: middle;
  margin-top: -2px;
  padding: 10px;
}

.whatsapp-button-content > span {
  padding-left: 10px;
}

.whatsapp-button:hover {
  background-color: #128c7e;
}
