.image-gallery-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 620px;
  margin: 10px 0px;
}

/*TODO Make sure this actually belongs here... this
is for the large image gallery in the logged in version
of the home page */

.loading-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.image-gallery-thumbnails {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.image-gallery-thumb {
  margin: 0px;
  border: solid 1px #cccccc;
  margin: 0px 5px 5px 5px;
  cursor: pointer;
  cursor: hand;
  height: 40px;
  width: auto;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-gallery-thumb > img {
  max-height: 35px;
  width: auto;
  object-fit: scale-down;
}

.image-gallery-thumb:hover {
  border: solid 1px #e77600;
}

.image-gallery-thumb-active {
  border: solid 1px #e77600;
}

@media only screen and (max-width: 950px) {
  .image-gallery-main {
    height: 250px;
    width: 450px;
  }
}

@media only screen and (max-width: 450px) {
  .image-gallery-main {
    width: 100%;
    max-width: 450px;
  }
}
