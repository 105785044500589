.cart-indicator-button-container {
  background-color: #ce3c25;
  text-decoration: none;
  color: #ffffff;
  letter-spacing: 1px;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 25px;
  min-width: 60px;

  border-radius: 4px;
  padding: 0px 3px;
}

.cart-indicator-button-container:hover{
  text-decoration: none;
}

.cart-indicator-button-icon {
  height: 22px;
}

.cart-indicator-spinner{
  color: #fff;
  text-align: center;
  font-size: 12px;

  width:14px;
  height: 14px;
  line-height: 14px
}

@media only screen and (max-width: 750px /*SMALL_MEDIA_SIZE*/) {
  .cart-indicator-button-container{
    margin-left: 10px;
  }

}

@media only screen and (max-width: 210px /*EXTRA_SMALL_MEDIA_SIZE*/) {
  .cart-indicator-button-container{
    margin-left: 0px;
  }

}