.buybox-main {
  color: #212121;

  border: 1px solid #b7b7b7;
  border-radius: 3px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;

  width: 245px;
  height: 280px;
  padding: 20px 0px 25px 0px;
}

.buybox-min-quantity {
  color: #8e8e8e;
  font-size: 12px;
  margin: 0px 0px -5px 0px;
}

.buy-box-manufacturer {
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
  letter-spacing: 1px;
}

.buybox-sub-container {
  display: flex;
  flex-direction: row;
  justify-content: content;
}

.buy-box-question-mark {
  width: auto;
  height: 12px;
  align-self: center;
  margin: 2px 5px;
}

.buybox-sub-container:hover {
  cursor: pointer;
}

.buybox-understand-the-price {
  color: #4f26a2 !important;
  font-size: 12px;
}

.buybox-price-content {
  visibility: hidden;

  position: absolute;
  top: 282px;
  left: 0px;
}

.buybox-sub-container:hover .buybox-price-content {
  visibility: visible;
}

.buybox-show-promo {
  visibility: visible !important;
}

.buybox-promo-tag {
  visibility: hidden;
  background-color: #4f26a2;
  color: white;

  border-radius: 5px;
  text-align: center;

  width: 70px;
  height: 25px;
}

.buybox-main > h4 {
  font-size: 18px;
  font-weight: 200;
  margin: 0px 0px -2px 0px;
}

.buybox-main > h5 {
  font-size: 12px;
  font-weight: 200;
}

@media only screen and (max-width: 750px) {
  .buybox-main {
    margin-top: 10px;
    height: 120px;
    padding: 4px 0px 7px 0px;
  }

  .buybox-sub-container {
    display: none;
  }

  .buybox-main > h4 {
    display: none;
  }

  .buybox-main > h5 {
    display: none;
  }

  .buybox-promo-tag {
    display: none;
  }

  .buy-box-manufacturer {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-top: 3px;
    margin-bottom: -4px;
  }
}
