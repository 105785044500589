.cart-total-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.cart-total-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}

.cart-total-price-info {
  visibility: hidden;

  position: absolute;
  top: -5px;
  right: -260px;
}

.cart-total-price-info-container:hover {
  cursor: pointer;
  text-decoration: underline;
}

.cart-total-price-info-container:hover .cart-total-price-info {
  visibility: visible;
}

@media only screen and (max-width: 750px) {
  .cart-total-price-info-container {
    display: none;
  }
}
