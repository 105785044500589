.search-result-hit-horizontal-main {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 160px;
}

.search-result-hit-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.search-result-hit-horizontal-title {
  color: #212121;
  font-size: 22px;
  font-weight: 700;
}

.search-result-hit-container > a:hover {
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .search-result-hit-horizontal-main {
    min-height: 120px;
  }

  .search-result-hit-horizontal-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 380px) {
  .search-result-hit-horizontal-main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-height: 260px;
    margin-bottom: 20px;
  }

  .search-result-hit-container {
    min-height: 85px;
  }

  .search-result-hit-horizontal-title {
    min-height: 45px;
    font-size: 14px;
    text-align: center;
  }
}
