.blend-line-item-conteiner {
  display: flex;
  height: 90px;
}

.blend-line-item-conteiner > span {
  font-size: 10px;
}

.blend-line-item-main {
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
  width: 100%;
}

.blend-line-item-content {
  display: flex;
  width: 98%;
}

.blend-line-item-image {
  width: 60px;
  margin-right: 15px;
}

.blend-line-item-selector > form > input {
  width: 103px;
  margin-right: 5px;
  margin-bottom: 3px;
  border: 1px solid #a5a5a5;
  border-radius: 4px;
  text-indent: 5px;
  height: 16px;
}
.blend-line-item-selector > form > button {
  width: 105px !important;
}

.blend-line-item-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-bottom: 0px;
  margin-left: 1px;
  width: 150px;
}

.blend-line-item-description > p {
  margin-bottom: 2px;
}

.blend-line-item-name {
  font-size: 12px;
  font-weight: 600;
}

.blend-line-item-manufacturer {
  font-size: 13px;
  font-weight: 600;
}

.blend-line-item-price {
  color: #2eb411;
  font-size: 12px;
  align-self: center;
  margin: 0 5px;
  width: 15%;
}

.blend-line-item-percentage {
  align-self: center;
  text-align: center;
  font-size: 24px;
  color: #ec5a1d;
  margin: 0 5px;
  width: 13%;
}

hr {
  width: 95%;
}

.trash-image {
  align-self: center;
  margin: 0 5px;
  border: none;
  background-color: #ffffff;
  padding: 0;
}

.trash-image:hover {
  cursor: pointer;
  outline: none;
}

.trash-loader {
  position: relative;
  text-align: center;
  font-size: 10px;
  border: 0px solid #fff;
  margin: auto 6px;
}

.blend-line-item-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 110px;
  margin-right: 5px;
}

.blend-line-item-content
  > .blend-line-item-quantity
  > .add-after-exists-button-main {
  width: 90px;
  margin: auto;
}

.blend-line-item-content
  > .blend-line-item-quantity
  > .add-after-exists-button-main
  > .add-after-exists-button-content {
  font-size: 10px;
  width: 45px;
  height: 25px;
}
.blend-line-item-content
  > .blend-line-item-quantity
  > .add-after-exists-button-main
  > button {
  font-size: 11px;
  height: 17px;
  width: 17px;
}
.blend-line-item-quantity {
  text-align: center;
  margin: auto;
  max-height: 40px;
}
.blend-line-item-quantity > span {
  font-size: 10px;
}

@media only screen and (max-width: 450px) {
  .blend-line-item-conteiner {
    height: 75px;
  }
  .blend-line-item-image {
    margin-right: 5px;
  }
  .blend-line-item-name {
    font-size: 10px;
  }
  .blend-line-item-manufacturer {
    font-size: 10px;
  }
  .blend-line-item-price {
    font-size: 10px;
    width: 16%;
  }
  .blend-line-item-content
    > .blend-line-item-quantity
    > .add-after-exists-button-main {
    width: 70px;
  }

  .blend-line-item-content
    > .blend-line-item-quantity
    > .add-after-exists-button-main
    > .add-after-exists-button-content {
    font-size: 8px;
    width: 35px;
    height: 20px;
  }
  .blend-line-item-content
    > .blend-line-item-quantity
    > .add-after-exists-button-main
    > button {
    font-size: 9px;
    height: 13px;
    width: 13px;
  }
  .blend-line-item-quantity > span {
    font-size: 8px;
  }
  .blend-line-item-percentage {
    font-size: 15px;
  }
}
