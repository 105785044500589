.my-account-address-item-main {
  background-color: #ededed;
  color: #474747;

  display: flex;
  align-items: center;

  width: 100%;
  margin-bottom: 10px;

  border: 1px solid #fff;
}

.my-account-address-item-main-hover {
  cursor: pointer;
}

.address-selected {
  background-color: #c0e8b7;
  border: 1px solid #2eb411;
}

.my-account-address-item-image {
  width: 90px;
  height: 90px;
}

.my-account-address-item-company-image {
  width: 90px;
  height: 130px;
}

.my-account-address-item-company-image-with-ie {
  width: 90px;
  height: 142px;
}

.my-account-address-item-information {
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  width: 280px;
}

.my-account-address-item-information > p {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  margin: 0px;
  white-space: nowrap;
}

.my-account-address-item-pencil {
  color: #8e8e8e;
  font-size: 25px;
  margin: 0px 90px 0px 20px;
}

.my-account-address-item-pencil:hover {
  cursor: pointer;
}

.my-account-address-item-trash {
  color: #8e8e8e;
  font-size: 25px;
  cursor: pointer;
  margin-right: 20px;
}

.my-account-address-item-trash:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .my-account-address-item-information {
    max-width: 220px;
  }

  .my-account-address-item-information > p {
    font-size: 12px;
    padding: 2px;
  }

  .my-account-address-item-pencil {
    font-size: 25px;
    margin: 0px 60px 0px 120px;
  }

  .my-account-address-item-trash {
    font-size: 25px;
  }
}

@media only screen and (max-width: 450px) {
  .my-account-address-item-image {
    display: none;
  }

  .my-account-address-item-company-image {
    display: none;
  }

  .my-account-address-item-pencil {
    margin: 0px 50px 0px 30px;
  }
  .my-account-address-item-information > p {
    font-size: 11px;
  }
}

@media only screen and (max-width: 375px) {
  .my-account-address-item-information {
    max-width: 190px;
  }
  .my-account-address-item-pencil {
    margin: 0px 35px 0px 35px;
  }
  .my-account-address-item-information > p {
    font-size: 10px;
  }
}

@media only screen and (max-width: 350px) {
  .my-account-address-item-pencil {
    margin: 0px 15px 0px 15px;
  }
}
