.search-no-result-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 50px 0;
}

.search-no-result-content {
  background-color: #ededed;
  color: #212121;
  font-size: 18px;
  text-align: center;

  border: 1px solid #ededed;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 400px;

  margin: 5px;
  padding: 5px;
}

.search-no-result-image {
  width: 100%;
  max-width: 180px;
  height: auto;

  margin-top: 5px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 400px /*SMALLER_MEDIA_SIZE*/) {
  .search-no-result-image {
    max-width: 150px;
  }
}
