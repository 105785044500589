.whatsapp-button-main {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 10px;
  right: 10px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.whatsapp-button-i-content {
  margin-top: 5px;
}

.whatsapp-button-main:hover {
  color: #fff !important;
}
