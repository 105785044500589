.other-methods-pane-title {
  font-size: 18px;
  letter-spacing: 1px;
}

.other-methods-picker-main {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.other-methods-option-main {
  background-color: #ce3c25;
  color: #fff;
  border: 1px solid #fff;

  font-size: 14px;
  letter-spacing: 1px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 570px;
  height: 35px;
  margin: 4px auto;
}

.other-methods-option-main:hover {
  cursor: pointer;
}

.other-methods-selected {
  background-color: #c0e8b7 !important;
  color: #000;
  border: 1px solid #2eb411;
}

.other-methods-pane-container {
  margin-top: 15px;
  position: relative;
}

.payment-on-delivery-info {
  text-align: left;
  font-size: 14px;
  position: absolute;
  top: 30px;
  float: left;
  width: 300px;
}

@media (max-width: 1610px) {
  .payment-on-delivery-info {
    float: none;
    position: relative;
    top: 0;
    min-height: 42px;
    text-align: center;
    font-size: 14px;
    width: 100%;
  }
}
