.product-page-recipe-header {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.product-page-recipe-header > h1 {
  color: black;
  font-size: 18px;
  font-weight: 300;
  text-align: center;

  display: inline;
  white-space: nowrap;
  margin: 0px 15px;
}

.product-page-recipe-header-title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-left: 40px;
  color: #eb5b1e;
  text-align: center;
}

.product-page-recipe-small-line {
  border-color: #b7b7b7;
  border-width: 2px;
  width: 150px;
}

.product-page-recipe-medium-line {
  border-color: #b7b7b7;
  border-width: 2px;
  width: 100%;
}

.product-page-recipe-main {
  height: 600px;
  overflow: hidden;
}

.product-page-recipe-main-fadeout {
  position: relative;
  bottom: 2em;
  height: 2em;
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -moz-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -o-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -ms-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.product-page-recipe-button-see-more {
  margin: 0 40px;
}

.button-show-full-recipes {
  border: none;
  background: none;
  color: #eb5b1e;
}

@media only screen and (max-width: 530px) {
  .product-page-recipe-main {
    height: 400px;
  }
}
