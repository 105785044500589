.department-not-found {
  display: flex;
  flex-direction: column;
}
.department-not-found h3 {
  text-align: center;
  margin-top: 20vh;
}
.department-not-found a {
  align-self: center;
  margin-top: 50px;

  padding: 7px 12px;

  border-radius: 6px;

  color: white;
  background-color: #ce3c25;

  font-weight: bold;
}
.department-not-found a:hover {
  text-decoration: none;

  background-color: #a12512;
  color: white;

  transition: 0.3s;
}
