.footer-container {
  background-color: #212121;
  padding: 0px 10px 0px 10px;
}

.footer-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 220px;
}

.footer-company-details {
  color: #a5a5a5;
  font-size: 10px;

  text-align: center;
  padding-bottom: 10px;
}

.footer-sub-container {
  font-weight: 700;
  font-size: 16px;

  display: flex;
  flex-direction: column;

  height: 170px;
  width: 210px;
}

.footer-sub-container > a {
  color: #ffffff !important;
  margin-bottom: 20px;
  text-decoration: none;
}

.footer-sub-container > p {
  color: #ffffff;
  margin: 0px 0px 20px 0px;
}

.footer-sub-container > a:hover {
  text-decoration: none;
}

.footer-sub-container > a:focus {
  text-decoration: none;
}

.footer-sub-container > img {
  height: auto;
  width: 80%;
}

.footer-social-media {
  display: flex;
  margin-top: 10px;
}

.footer-social-media > a {
  color: #a5a5a5 !important;
  font-size: 20px;
  margin: 0px 12px 0px 5px;
}

.footer-version-information {
  color: #a5a5a5 !important;
  font-size: 12px;
}

@media only screen and (max-width: 750px) {
  .footer-main {
    height: 220px;
  }

  .footer-sub-container {
    display: none;
  }
}
