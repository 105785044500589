.free-shipping-notice-main {
  background-color: #eb5b1e;
  color: white;
  width: 100%;
  min-height: 30px;
  border-radius: 4px;
  text-align: center;
  letter-spacing: 1px;
  padding: 2px 5px 0px 5px;
  font-size: 16px;
  margin-top: 5px;
}

@media only screen and (max-width: 500px) {
  .free-shipping-notice-main {
    font-size: 14px !important;
    padding: 4px 5px 0px 5px;
  }
}
