.image-carousel-img {
  position: relative;
  width: 100%;
  height: auto;
}

.banner-arrow-container-right {
  position: absolute;
  z-index: 100;

  top: 40%;
  right: 5px;
}

.banner-arrow-container-left {
  position: absolute;
  z-index: 100;

  top: 40%;
  left: 5px;
}

@media only screen and (max-width: 750px /*SMALL_MEDIA_SIZE*/) {
  .banner-arrow-container-right {
    display: none;
  }

  .banner-arrow-container-left {
    display: none;
  }
}
