.department-hit-main {
  width: 190px;
  height: 250px;
  background-color: #f6f6f6;
  margin-right: 10px;
  margin-bottom: 10px;
}

.department-hit-image-container {
  width: 100%;
  height: 60px;
}

.department-hit-image {
  cursor: pointer;
  width: 100%;
  height: 60px;
}

.department-placeholder {
  width: 100%;
  height: 60px;
  background-color: #777777;
}

.department-hit-title > h3 {
  color: #212121;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 20px;

  margin-top: 3px;
  margin-bottom: 5px;
}

.department-hit-title:hover {
  color: #212121;
  text-decoration: none;
  cursor: pointer;
}

.department-hit-text-group {
  display: inline-flex;
  flex-direction: column;
  padding: 10px 5px;
}

.department-hit-entries {
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 1px;
  line-height: 22px;
  color: #374140;
  cursor: pointer;
  margin-bottom: 8px;
}

.department-hit-entries:hover {
  text-decoration: none;
}

.deparment-hit-see-more {
  font-size: 14px;
  margin-top: -2px !important;
}

.deparment-hit-see-more > a:hover {
  color: #0275d8;
}

@media only screen and (max-width: 1100px) {
  .department-hit-title > h3 {
    font-size: 15px;
  }

  .department-hit-text-group {
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 500px) {
  .department-hit-main {
    width: 190px;
    height: 250px;
  }
  .department-hit-title > h3 {
    font-size: 14px;
  }
}
