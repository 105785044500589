.my-account-navigator-main{
  display: flex;
  flex-direction: column;
}

.my-account-navigator{
  display: flex;
  flex-direction: column;
}

.my-account-navigator-main-link{
  background-color: #eb5b1e;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;

  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 180px;
  height: 30px;
  margin-bottom: 5px;
}

.my-account-navigator-main-link-active{
  background-color: #F0885A;
  color: #fff;
}

.my-account-navigator-main-link:hover{
  color: #fff;
  text-decoration: none;
}

.my-account-navigator-logout{
  background-color: #D0021B;
}

.my-account-mobile-navigator{
  display: none;
}

@media only screen and (max-width: 750px /*SMALL_MEDIA_SIZE*/) {
  .my-account-mobile-navigator{
    display: block;
    background-color: #a5a5a5;
    color: #2f2f2f;
    letter-spacing: 1px;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 5px;
    height: 30px;
  }

  .my-account-mobile-navigator:hover{
    cursor: pointer
  }

  /*.my-account-navigator{
    display: none;
  }*/

  .my-account-navigator-main-link{
    background-color: #eb5b1e;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;

    border-radius: 3px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 30px;
    margin-bottom: 5px;
  }

  .my-account-navigator-logout{
    background-color: #D0021B;
  }

  .my-account-navigator-main-link-active{
    background-color: #F0885A;
    color: #fff;
  }

}