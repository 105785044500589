.about-us-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 50px 0px;
  padding: 10px;
}

.about-us-stamp {
  width: 170px;
  margin-bottom: 30px;
}

.about-us-telephone-mobile {
  display: none;
}

.about-us-telephone {
  display: inline;
}

.about-us-text > p {
  letter-spacing: 1px;
  font-weight: 100;
}

.about-us-text > p > span {
  font-weight: 600;
  color: #ce3c25;
}

.contact-us-social-media {
  display: flex;
  align-items: center;
  margin-top: -5px;
  margin-bottom: 16px;
}

.contact-us-social-media > a {
  color: #ce3c25 !important;
  margin: 0px 10px;
  font-size: 25px;
}

@media only screen and (max-width: 750px) {
  .about-us-telephone {
    display: none;
  }

  .about-us-text > p > span > a {
    font-weight: 600;
    color: #ce3c25;
  }

  .about-us-text > p > span > a:hover {
    cursor: pointer;
    text-decoration: none;
  }

  .about-us-telephone-mobile {
    display: inline;
  }
}
