.price-with-unit-main {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-around;
  width: 55%;
}

.price-with-unit-promotion {
  width: 56%;
}

.price-with-unit-promotion > h6 {
  display: inline !important;
}

.price-with-unit-promotion > h5 {
  color: #2eb411 !important;
}

.price-with-unit-main > h6 {
  display: none;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  text-decoration: line-through;
  color: #757575;
  margin-right: 20px;
}

.price-with-unit-main > h5 {
  color: #757575;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0px;
}

@media only screen and (max-width: 450px) {
  .price-with-unit-main > h5 {
    font-size: 10px;
  }
  .price-with-unit-main > h6 {
    font-size: 8px;
    margin-right: 5px;
  }
  .price-with-unit-main {
    width: 70%;
  }
  .price-with-unit-promotion {
    width: 70%;
  }
}
